//
// Widget styles
//

&.air {
    &:not(.single) .list:last-of-type { border-bottom: 0px solid $softGrey; }
}

&.air, &.bigrain {
    .cake-holder {
        transform: scale( 1.1, 1.1 );
    }
}

// Repos header and cake-icon
&.cloud, &.rain {
    .titlebar {
        text-align: left;
        padding-right: 80px;
    }
    
    .cake-holder {
        transform: scale( 0.9, 0.9 );
        position: absolute;
        right: -20px;
        top: -45px;
    }
    
    .cake-icon { text-shadow: 3px 3px 0px rgba( 0, 0, 0, 0.07 ); }
}

// Negative margin grid
&.air, &.cloud {
    .today {
        margin-left: -15px;
        margin-right: -15px;
    }
}

&.bigrain {
    .today {
        margin-left: -25px;
        margin-right: -25px;
    }
}

// Extra offseet for headers
&.rain {
    .list  { padding: 0px 15px; }
}



