//
//	Widget ContentSlider
//	multifunctional widgets
//	shows a slideshow based on selected contenttype
//	options for
//	- showing content (description) of image text
//	- show/hide navigation
//	- autoplay (in combination with JS)
//
.widget.contentSlider {
    @import 'base';
    @import 'textbox';
    @import 'navigation';
    @import 'animations';
    @import 'options';
    @import 'styles';
}


// High contrast
#page.highcontrast .widget.contentSlider.base { border: 0px; }
#page.highcontrast .widget.contentSlider .base.textbox {
    border: 1px solid $black;
    background-color: $white;
    color: $black;
}

#page.highcontrast .widget.contentSlider .opt {
    border: 1px solid $black;
    background-color: $white;
    color: $black;
}

// Focus styling
#page.user-is-tabbing .slide:focus {
    border: 7px solid $focus-outline-color;

    .textbox {
        outline: 3px dotted $focus-outline-color;
        outline-offset: 5px;
    }
}
