//
//  Widget index
//  - shows a overview of letters, linked to a SETUP url as target
//
.widget.index {
    &, * {
        position: relative;
        box-sizing: border-box;
    }
    
    .letters {
        padding: 0px 0px 0px 20px;
        
        a {
            display: block;
            float: left;
            min-width: 30px;
            height: 30px;
            line-height: 30px;
            margin: 3px;
            text-align: center;
            text-decoration: none;
            color: inherit;
            background-color: rgba( 0, 0, 0, 0.05 );
            padding: 0px 10px;
            transition: all 0.1s linear;
            border-radius: 3px;
        }
        
        &:after {
            content: " ";
            clear: both;
            display: block;
        }
    }
    
    &.floatTitle .holder {
        display: flex;
        flex-flow: row nowrap;
        
        .title {
            flex: 0 0 auto;
            line-height: 30px;
            padding-left: 10px;
            margin: 3px;
        }
        
        .letters { flex: 1 1 auto; }
    }
    
    //
    //  Widget styles
    //
    &.air {
        .letters a {
            background-color: transparent;
            margin: 3px 0px;
            padding: 0px 5px;
            min-width: 20px;
            height: 20px;
            line-height: 20px;
            
            &:hover {
                background-color: rgba( 255, 255, 255, 0.05 );
                text-decoration: underline;
            }
        }
        .title { font-size: 1rem; }
    
        &.floatTitle .title {
            padding-left: 0px;
            line-height: 20px;
            font-size: 1rem;
        }
    }
    
    &.air, &.cloud {
        .overlay { padding: 0px; }
    }
    
    &.cloud, &.rain, &.bigrain {
        &.clearTitle .letters { padding: 10px 12px; } // 12px is base of 15px minus margin of each letter
        
        .letters a { border: 1px solid transparent; }
        
        .letters a:hover {
            background-color: transparent;
            border-color: rgba( 0, 0, 0, 0.25 );
        }
    }
    
    &.rain, &.bigrain {
        .letters a {
            min-width: 40px;
            height: 40px;
            line-height: 40px;
        }
    
        &.floatTitle .title {
            line-height: 40px;
            padding-left: 15px;
        }
    }
    
    &.bigrain {
        &.floatTitle .title { font-family: 'oswald-bold'; }
    }
}

// Change title color of air based on row background
.row.image, .row.bgColorA, .row.bgColorB,
.row.bgColorgo, .row.bgColorGO,
.row.bgColorBlack, .row.bgColorDarkGrey, .row.bgColorGrey {
    .index.air.floatTitle .title { color: #FFFFFF; }
}
