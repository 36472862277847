//
//  Birthdays widget
//  - shows a list of upcoming and / or past birthdays
//  - shows content type employees and / or persons
//
.widget.birthdays {
    
    // Reset
    &, .person {
        box-sizing: border-box;
        position: relative;
    }
    
    .content {
        @include clearAfter();
    }
    
    // Include parts
    @import 'layout';
    @import 'headers';
    @import 'cake-icon';
    @import 'person';
    @import 'today';
    
    @import 'styles';
    
}
