//
//	RIS Specific overwrite, from old search style
//

// Fix containers auto-height
#page.zoeken #wrapper {
	.container { min-height: auto; }
	#content { min-height: 500px; }

	#gosFoldout .container {
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.container .container { margin: 0px; }
}

// User is tabbing

// No animation when tabbing
.user-is-tabbing .gos {
	.foldout {
		max-height: initial;
		display: none;
	}

	.foldout.active { display: block; }
}
