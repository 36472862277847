/* ############# */
/* #   Debug   # */
/* ############# */
#debugPanel
{
	position: fixed;
	top: 100px;
	left: 0px;
	width: 200px;
	background: #FFFFFF;
	border: 3px solid #EFEFEF;
	padding: 20px;
	z-index: 999;
	margin-left: 0px;
	transition: margin-left 0.5s;
}
#debugPanel .switch { position: absolute; right: 10px; top: 10px; cursor: pointer; }
#debugPanel.mini { margin-left: -200px; transition: margin-left 0.5s; }