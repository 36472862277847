/* #################### */
/* #   Header & Menu  # */
/* #################### */

/* Default menu */
#navigation
{
	position: relative;
	top: 10px;
	width: 100%;
	z-index: 20;
	border: 0px;
}

#navigation.hideBackground:not(.preserveBackground) { background: transparent; }
#navigation .menu { margin: 0px; width: auto; border: 0px; }

/* Main menu home fix */
#navigation .home a:before
{
	content: "\e622";
	font-family: "defaultIcons";
	font-size: 100%;
	line-height: 0;
	position: relative;
	top: 2px;
	height: 300px;
	margin-right: 5px;
}

@media (min-width: 700px) {
	#navigation .home span { display: none; }
	#navigation .home a:before { margin-left: 0px; margin-right: 0px; }
	#navigation .home a { padding: 0px 15px; }
}

@media print {
	#navigation { display: none; }
}

#header
{
display: none;
position: relative;
top: 0px;
background: #FFFFFF;
width: 100%;
z-index: 8;
}

#logo, #assistent
{
width: 40%;
margin: 30px 0px 0px 0px;
position: relative;
float: left;
}

#logo a
{
display: block;
background: url(../images/logo.svg) no-repeat left top;
min-height: 80px;
text-indent: -9999px;
}


@media print {
	#header { border-bottom: 1px solid #CCCCCC; height: auto; }
	#logo a { background: transparent; min-height: auto; }
	#logo:before { content: url(../images/logo.svg); margin: 0.5cm 0px; }
}

/* header assistent links */
#assistent { float: right; margin: 0 20px 0 0;  }

#assistent .quicksearch {
min-width: 270px;
width: 100%;
clear: both;
margin-left: 15px;
position: relative;
margin-top: 35px;
}

.rsbtn .rsbtn_powered { display:none;}

.rsbtn { float: right; margin: 42px 0 10px; }

#assistent .about { float: right; text-align: left; }
#assistent .about a { 
color: #AAAAAA;
display: inline-block;
font-size: 0.9rem;
margin-left: 0;
padding: 9px;
text-decoration: none;
vertical-align: top;
}
#assistent .about a:hover { text-decoration: underline; } 
#assistent .about a.social {
	background: no-repeat 50% 50%;
	color: #cbcece;
	display: inline-block;
	font-size: 20px;
	height: 40px;
	line-height: 40px;
	margin: 0;
	padding: 0;
	text-align: center;
	width: 40px;
}
#assistent .about a.social:hover { background-color: #f4f7f9; }
#assistent .social.newwindow:after { content: ""; margin-left: auto; }

#assistent .about + .quicksearch { margin-top: 10px; }
#assistent .about + .quicksearch + .rsbtn { margin-top: 16px; }

@media (min-width: 768px){
	#assistent .quicksearch {
	float: right;
	width: 270px;
	}
}

@media (max-width: 790px) {
	#assistent  { float: left; margin-right: 0; width: 100%; }
	#assistent .about { display: none; }
}

@media print {
	#assistent { display: none; }
}