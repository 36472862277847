//
//	Containers | Base layout
//
.results, .rightcolumn { margin-bottom: 70px; }
.input, .search-filter-toolbar, .results {
	max-width: 500px;

	@media (min-width: $landscape){
		max-width: 750px;
	}
}
