//
//  Generic item styling
//  - used in widget
//
.widget {
    .item {
        display: block;
        border-bottom: 1px solid rgba(0,0,0,0.15);
        margin-bottom: 10px;
        padding-bottom: 10px;
        text-decoration: none;
    }

    .item:not(a) {
        color: inherit;
    }

    a.item {
        &:hover { background-color: $f5; }

        @include clearAfter();

        .more {
            display: block;
            text-align: right;
        }

        p { color: $darkGrey; }

        &:last-of-type:not(:first-of-type) { border-bottom: 0px; }
    }

    .thumb-and-content {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;

        .thumb, .imageheader, .map { flex: 0 0 auto; }
        .itemcontent { flex:  1 1 auto; }
    }

    // Style specific tweaks
    .rain {
        &:not(.introlist) {
            .content .item { padding: 10px 15px; }
        }
    }

    .rain, .cloud {
        .introlist .item { margin-bottom: 0px; padding: 0px; }
        .item:first-of-type { margin-top: 5px; }
    }
}


