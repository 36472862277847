//
//  Profile icon
//  - shows a default icon, profile-image or initials of the logged in user
//
.profile-icon {
    display: inline-block;
    text-align: center;
    color: $white;
    background: #37AFE4 linear-gradient( #37AFE4, #37D5E4 ) no-repeat top center;
    background-size: cover;
    position: relative;
    border-radius: 100%;
    text-decoration: none;
    box-sizing: border-box;
    transition: all 0.2s linear;
    
    // Default size
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 24px;
    
    &:hover { opacity: 0.7; }
    
    // Other sizes
    
    &.extra-large {
        font-size: 54px;
        @include equalAll( 120px );
    }
    
    &.larger {
        font-size: 32px;
        @include equalAll( 70px );
    }
    
    &.smaller {
        font-size: 16px;
        @include equalAll( 40px );
    }
    
    &.tiny {
        font-size: 10px;
        @include equalAll( 20px );
    }
    
    .fa {
        margin: 0px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate( -50%, -50% );
    }
    
    // Other styles
    &.not-logged-in, &.no-user {
        background: #e4e4e4;
        font-size: 32px;
        
        &:before {
            content: " ";
            position: absolute;
            width: 90%;
            height: 6px;
            background-color: #e4e4e4;
            border-radius: 4px;
            bottom: 40%;
            left: 5%;
            z-index: 5;
            transform-origin: center center;
            transform: rotate(-45deg);
        }
    }
}
