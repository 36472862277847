//
//  News and media
//  - styling for news and media category overviews
//

// Common IMG element
.news, .newslist_simple, .media, .events {
    .img {
        width: 120px;
        height: 80px;
        float: left;
        margin-right: 20px;
        margin-top: 5px;
        position: relative;
        z-index: 2;
    }
}

//
//  Media overview
//
.media .img {
    height: 90px;
    img { position: relative; margin-top: -26px; }

    .media-thumb:after {
        position: absolute;
        z-index: 4;
        border: 8px solid rgba(255, 255, 255, 0.6);
        border-radius: 60px;
        width: 60px;
        height: 60px;
        background: rgba(255, 255, 255, 0.3);
        color: #FFFFFF;
        font-size: 3rem;
        font-family: 'defaultIcons';
        content: '\e6ab';
        top: 50%;
        left: 50%;
        margin-left: -34px;
        margin-top: -34px;
        text-align: center;
        line-height: 60px;
        cursor: pointer;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);

        &:hover {
            opacity: 0.9;

            &:after {
                border-color: $primary;
                transition: border-color 0.3s;
            }
        }
    }
}

// Media side-by-side view
.category .media {

    @media ( min-width: $mobileLandscape ) {
        .items {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
        }

        .item {
            flex: 0 0 49%;
            flex-direction: column;

            .img, .thumb {
                width: 100%;
                flex-basis: auto;
            }
        }
    }

    @media ( min-width: $landscape ) {
        .item { flex-basis: 31%; }
    }
}

// First two side-by-side
.category .news {
    @media ( min-width: $mobileLandscape ) {
        .items {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
        }

        .item {
            flex: 0 0 100%;

            &:nth-of-type( -n+2 ) {
                flex-basis: 49%;
                flex-direction: column;

                .img {
                    width: 100%;
                    height: 120px;
                    flex-basis: auto;
                }
            }
        }
    }
}



