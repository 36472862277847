//
// Textbox
//
.textbox {
    box-sizing: border-box;
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 100%;
    height: auto;
    max-height: 200px;
    overflow: hidden;
    background-color: rgba( 255, 255, 255, 0.9 );
    padding: 15px 10px 10px 20px;
    
    @media( min-width: 768px ) {
        width: 70%;
    }
    
    .title {
        font-size: 1.2rem;
        color: inherit;
        margin-bottom: 5px;
    }
    
    .smallcontent { font-size: 0.9rem; }
    p { margin-bottom: 5px; }
    
    .more {
        text-align: right;
        float: right;
    }
}
