//
//  List of employee
//  - found results by search value / filters or by letter
//
.overview {
    &, .head, .profile-tile {
        position: relative;
        box-sizing: border-box;
        
        @include clearAfter();
    }
    
    // Head of list with sort options
    $headItemHeight: 30px;
    
    .head {
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid $softGrey;
        
        * {
            padding: 0px;
            margin: 0px;
            line-height: $headItemHeight;
        }
        
        .title {
            float: left;
            font-size: 1rem;
        }
        
        .sort-options {
            float: right;
            display: flex;
            flex-flow: row nowrap;
            align-content: flex-start;
            align-items: center;
            
            input[type='checkbox'] { display: none; }
            
            select {
                min-width: 100px;
                margin: 0px 5px;
            }
            
            .sort-title {
                display: none;
                @media ( min-width: $portrait ) {
                    display: block;
                }
            }
            
            .sort-order {
                border-radius: 3px;
                padding: 0px 0px 0px 10px;
                transition: all 0.2s linear;
                width: 90px;
                position: relative;
                
                &:hover { background-color: $e4; }
                span { line-height: 20px; }
                
                .fa {
                    position: absolute;
                    text-align: center;
                    right: 0px;
                    top: 0px;
                    
                    @include equalAll( $headItemHeight )
                }
                
                .desc { display: none; }
                
                input[type='checkbox']:checked+label {
                    font-style: normal;
                    
                    .desc { display: inline; }
                    .asc { display: none; }
                }
            }
        }
        
        // Fancy overview switch button
        .overview-type {
            border-radius: 3px;
            border: 1px solid transparent;
            cursor: pointer;
            margin-left: 8px;
            
            &:before {
                content: " ";
                position: absolute;
                left: -5px;
                height: 80%;
                top: 10%;
                border-left: 1px dotted $lightGrey;
            }
    
            &:hover {
                background-color: $white;
                border-color: $e4;
            }
            
            &, .icon-tiles, .icon-list {
                display: block;
                position: relative;
                box-sizing: border-box;
                text-align: center;
                color: $lightGrey;
                
                @include equalAll( $headItemHeight );
            }
            
            .icon-list { display: none; }
            
            :checked+label {
                .icon-tiles{ display: none; }
                .icon-list { display: block; }
            }
        }
    }
    
    // List of employees
    .list {
        .profile {
            position: relative;
            box-sizing: border-box;
            border-radius: 3px;
        }
        
        .profile.flat {
            width: 100%;
            display: inline-block;
            
            a {
                display: block;
                line-height: 20px;
                padding: 5px 5px 5px 30px;
            }
            
            .fa {
                color: $lightGrey;
                display: inline-block;
                position: absolute;
                left: 0px;
                top: 0px;
                text-align: center;
                
                @include equalAll( 30px );
            }
            
            &:hover { background-color: $e4; }
        }
        
        // Column / list view
        @media ( min-width: $mobileLandscape ) {
            &.columns {
                column-count: 2;
                column-gap: 40px;
            }
        }
    
        @media ( min-width: $landscape ) {
            &.columns {
                column-count: 3;
                column-gap: 40px;
            }
        }
        
        // Tile view
        &.tiles {
            display: flex;
            flex-flow: row wrap;
            box-sizing: border-box;
            justify-content: flex-start;
            align-content: flex-start;
            
            .tile {
                flex: 0 0 auto;
                min-width: 160px;
                padding: 20px 5px;
                margin: 0px 0.5% 20px 0.5%;
                text-decoration: none;
                text-align: center;
                
                .profile-icon {
                    border: 8px solid $softGrey;
                    font-size: 54px;
                    @include equalAll( 120px, 8px );
                }
                
                &:hover {
                    background-color: $white;
                
                    .profile-icon {
                        transform: scale( 1.1 );
                        opacity: 1;
                    }
                }
                
                .name {
                    font-size: 1rem;
                    font-weight: 900;
                    display: block;
                    clear: both;
                    line-height: 1.2;
                    margin-top: 20px;
                    text-transform: uppercase;
                    color: $mediumGrey;
                }
                
                .function {
                    color: $lightGrey;
                    display: block;
                    font-size: 0.9rem;
                }
            }
        }
    }
}
