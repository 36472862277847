/* ################################## */
/* #       Formulier  Preview		# */
/* ################################## */
#formpreview_options{
	background: none repeat scroll 0 0 #CECECE;
	border: 1px solid;
	display: inline-block;
	margin-left: 82%;
	margin-top: -37px;
	padding: 3px 5px 0;
}

#formpreview_options span{
	line-height: 24px;
	padding: 0 0 0 55px;
	font-weight:bold;
	cursor:pointer;
}

#formpreview_options span.switchOn{
	background:url("../images/on-off-switch.png") no-repeat 0 0;
}

#formpreview_options span.switchOff{
	background:url("../images/on-off-switch.png") no-repeat 0 -18px;

}
#formpreview_options span.reset{
	background:url("../images/resetpreviewbutton.png") no-repeat 0 0;
}