// Readspeaker after title
.readspeakerButton.afterTitle {
    clear: both;
    margin: 0px;
    
    .rsbtn {
        margin: 0px;
        float: none;
    }
}

#content:not(.home):not(.widgets) .readspeakerButton .container {
    margin: -15px 0px 20px 0px;
    width: 100%;
}

#system .readspeakerButton .container {
    margin: 0px 0px 20px 0px;
    width: 100%;
}

#page.zoeken #wrapper #gosearch .readspeakerButton .container {
    margin: 20px 0px;
    width: 100%;
}

#content.home, #content.widgets {
    .readspeakerButton {
        display: none;
        background-color: $backBlue;
        border: 1px solid $blue;
        padding: 15px 0px;

        &.active {
            display: block;
        }
    }
}

@media print {
    .readspeakerButton { display: none; }
}
