// Article and sidebar
article, .column, aside, .sidebar {
    clear: both;
    margin-bottom: 50px;
}

@media ( min-width: $landscape ){
    
    article, .column {
        float: left;
        clear: left;
        width: calc(100% - 340px);
    }
    
    // Aside, sidebar
    aside, .sidebar {
        float: right;
        clear: right;
        width: 260px;
        margin-left: 50px;
    }
    
    .switchColumns article, .switchColumns .column {
        float: right;
        clear: right;
        
    }
    
    .switchColumns aside, .switchColumns .sidebar {
        float: left;
        clear: left;
        margin-left: 0px;
        margin-right: 50px;
    }
    
}

.column.home, .column.fullWidth, .column.widgets {
    width: 100%;
}
