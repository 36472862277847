//
//  Toptask widget
//  - shows a responsive grid overview of banners
//  - on mobile the list is minimized to 4 items and expandable by JS
//
.widget.toptask {
    
    // Base layout
    .task {
        color: inherit;
        text-decoration: none;
        min-height: 60px;
        line-height: 20px;
        box-sizing: border-box;
        
        &, * {
            transition: all 0.1s ease-in;
        }
        
        .taskname {
            width: calc( 100% - 60px );
            float: left;
            font-size: 1.3rem;
            padding: 20px 0px 20px 20px;
            box-sizing: border-box;
        }
        
        .taskicon {
            display: block;
            float: right;
            width: 60px;
            font-size: 1.8rem;
            margin: 0px;
            text-align: center;
            line-height: 60px;
            position: relative;
        }
        
        &:hover {
            opacity: 0.8;
        }
        
        // Larger tiles on tablet and desktop
        @media ( min-width: $portrait ) {
            height: 120px;
            
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            
            .taskname {
                padding-left: 35px;
            }
    
            .taskicon {
                padding-right: 20px;
                font-size: 2.4rem;
            }
    
            &:hover {
                .taskicon {
                    font-size: 3.2rem;
                }
            }
        }
    }
    
    // More button
    @media ( min-width: $landscape ) {
        .moreTile { display: none; }
    }
    
    .moreLink {
        text-align: center;
        width: 100%;
        
        span { pointer-events: none; }
        
        .moreIcon {
            margin-left: 10px;
            position: relative;
        }
    }

    .txt-less { display: none; }
    
    // Mobile only tweaks
    @media ( max-width: ( $landscape - 1px ) ) {
        .task { margin-bottom: 5px; }
        
        // Hide more than 4 on tablet
        :not(.active) .task:nth-of-type( n+5 ) {
            display: none;
        }
        
        .active  {
            .txt-less { display: inline-block; }
            .txt-more { display: none; }
        }
    }
    
    @media ( min-width: $landscape ) and ( max-width: ($desktop - 1px)  ) {
        .task {
            .taskname { padding-left: 15px; }
            .taskicon  { padding-right: 5px; }
        }
    }
    
    //
    //  Widget styles
    //
    &.air, &.cloud {
        .taskname { font-weight: 300; }
    }
    
    &.cloud, &.rain, &.bigrain {
        .toptask, .button {
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        }
    }
    
    // Air
    &.air {
        .bgColorTransparent .taskname { padding-left: 0px; }
    }
    
    // Cloud
    &.cloud {
        .task { border-radius: 5px; }
    }
    
    // BigRain
    &.bigrain {
        .taskname { font-family: 'oswald-bold', Sans-Serif; }
    }
    
    // High contrast
    #page.highcontrast & {
        .task {
            background-color: $white;
        }
        .taskname { font-weight: normal; }
        .taskname, .taskicon { color: $black; }
    }
}
