//
//  Base elements employees
//
& {
    margin-bottom: 80px;
}

h3, h4, .title {
    font-weight: bold;
    font-size: 0.9rem;
    color: $darkGrey;
}

.block {
    padding: 25px;
    background-color: $white;
    margin-bottom: 25px;
}

.no-employees {
    text-align: center;
    margin: 20px auto;
    
    p {
        font-style: italic;
        color: $lightGrey;
        font-size: 1.2rem;
        font-weight: 300;
        margin: 20px;
    }
}
