//
//  Long calendar
//  - horizontal 'calendar like' navigation bar for events
//

.longcalendar {
    position: relative;
    box-sizing: border-box;
    padding: 15px 5px;
    margin: 0px 0px 30px 0px;
    background: $white;
    box-shadow: 0px 0px 5px rgba( 0, 0, 0, 0.1 );
    display: flex;
    flex-flow: row nowrap;
    border-radius: 3px;
    z-index: 2;

    // Set order of elements
    .selection-holder {
        flex: 1 1 auto;
        border-left: 1px solid $e4;
        border-right: 1px solid $e4;
        padding: 0px 15px;
        margin: 0px 5px;

        h3, .selected-period {
            margin: 0px;
            line-height: 30px;
            flex: 1 1 auto;
            font-size: 1.3rem;
        }
    }

    .next-month, .previous-month {
        flex: 0 0 40px;
        line-height: 100%;
        text-align: center;
        padding-top: 10px;
        border-radius: 3px;
        font-size: 1.4rem;

        &:hover {
            background-color: $focus-outline-color;
            color: $white;
        }
    }

    .bar {
        display: block;

        @media ( min-width: $mobileLandscape ) {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
        }
    }

    .selection {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: flex-start;
    }

    .bar { margin-bottom: 15px; }

    .button {
        margin: 0px;
        padding: 3px 15px;
    }

    .select {
        position: relative;
        box-sizing: border-box;
        width: 120px;
        margin-left: 10px;
        height: 38px;

        * { box-sizing: border-box; }

        .pointer {
            display: block;
            padding-right: 7px;
            z-index: 4;
            background-color: transparent;
            pointer-events: none;
            position: absolute;
            right: 0px;
            width: 30px;
            text-align: center;
        }

        ul {
            position: absolute;
            z-index: 3;
            border: 1px solid $e4;
            background-color: $white;
            border-radius: 3px;
            width: 100%;
            list-style: none;
            margin: 0px;
            pointer-events: none;
        }

        a {
            text-decoration: none;
            font-size: 10pt;
            padding: 5px 0px 5px 10px;
            display: block;
            line-height: 26px;
            color: inherit;

            &:hover {
                background-color: $focus-outline-color;
                color: $white;
            }
        }

        // Hide / show
        li {
            &:not(.active) { display: none; }
        }

        &.active, &:hover {
            ul { pointer-events:  visible; z-index: 5;}
            li { display: block; }
            .pointer { pointer-events: none; }

            li.active a {
                background-color: $f5;
                font-style: italic;

                &:hover {
                    background-color: $focus-outline-color;
                    color: $white;
                }
            }
        }
    }

    // Different layout for mobile
    @media ( max-width: ( $mobileLandscape - 1px )) {
        .selection {
            display: block;

            .select, .today {
                margin: 0px 0px 10px 0px;
                width: 100%;
            }

            .today { text-align: center; }
        }
    }

    // Overview of days
    .days {
        list-style: none;
        margin: 0px;

        li { display: inline-block; }

        a {
            display: inline-block;
            width: 30px;
            line-height: 30px;
            text-align: center;
            text-decoration: none;

            &:hover {
                background-color: $focus-outline-color;
                color: $white;
            }
        }

        a.active, li.today a {
            color: $white;
            font-weight: bold;
        }

        li.today { background-color: $primary; }
    }
}
