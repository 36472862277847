//
//  Forum window
//  - styling for overlay windows for confirmations or information
//
.forum-overlay {
    z-index: 50;
    pointer-events: none;
    
    &, &:after, .forum-window {
        position: fixed;
        left: 0px;
        top: 0px;
        right: 0px;
        bottom: 0px;
        box-sizing: border-box;
    }
    
    &:after {
        content: " ";
        z-index: -1;
        background-color: rgba( 255, 255, 255, 0.85 );
        opacity: 0;
        transition: all 0.1s ease-in;
    }
    
    .forum-window {
        background-color: $white;
        display: flex;
        flex-flow: column nowrap;
        opacity: 0;
        transition: all 0.2s ease-in;
        
        @media ( min-width: $portrait ) {
            left: calc( 50% - 250px );
            top: 300px;
            right: auto;
            bottom: auto;
            width: 500px;
            border: 1px solid $e4;
            box-shadow: 0px 0px 15px rgba( 0, 0, 0, 0.15 );
            
            margin-top: -50px;
            
        }
        
        .window-header, .window-footer, .window-content {
            position: relative;
            box-sizing: border-box;
        }
        
        .window-header, .window-footer { flex: 0 0 auto; }
        
        .title {
            padding-right: 40px;
            margin-bottom: 5px;
        }
        
        .opt {
            display: block;
            position: absolute;
            right: 10px;
            top: 10px;
            text-align: center;
            border-radius: 3px;
            margin: 2px;
            color: $lightGrey;
            cursor: pointer;
            
            @include equalAll(30px);
            
            &:hover { background-color: $f5; }
            
            .icon {
                margin: 0px;
                pointer-events: none;
            }
        }
        
        .window-content {
            flex: 1 1 auto;
            padding: 20px;
        }
        
        .window-footer {
            padding: 5px;
            border-top: 1px solid $e4;
            background-color: $f5;
            &.toolbar {margin: 0px; }
        }
    }
    
    &.active {
        pointer-events: visible;
        
        &:after { opacity: 1; }
        
        .forum-window {
            margin-top: 0px;
            opacity: 1;
        }
    }
}
