//
// Position of info button on search page
//
#page.zoeken {
    .search-info-button {
        position: absolute;
        right: 0px;
        z-index: 2;
        padding: 9px 18px;
    }

    .search-info-overlay .window-content h3:first-of-type {
        display: none;
    }
}
