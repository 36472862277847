//
//  Share this page
//  - shows a bar with social media icons (with wich a user can share the current page)
//
$sizeMobile : 44px;
$sizeDesktop : 32px;

.share-this-page {
    position: relative;
    clear: both;
    margin-top: 50px;
    
    @media ( min-width: $mobileLandscape ) {
        display: flex;
        flex-flow: row nowrap;
    }
    
    .share-title {
        line-height: $sizeMobile;
        font-size: 1rem;
    
        @media ( min-width: $mobileLandscape ) {
            line-height: $sizeDesktop;
            margin-right: 10px;
            flex: 0 0 auto;
        }
        
        .icn {
            display: inline-block;
            margin-right: 5px;
        }
    }

    .share-links {
        @media ( min-width: $mobileLandscape ) {
            flex: 1 1 auto;
        }
    }
    
    .share-item {
        display: inline-block;
        position: relative;
        margin: 0px 2px;
        
        a {
            display: block;
            color: $white;
            cursor: pointer;
            
            .icn {
                background-color: $primary;
                width: $sizeMobile;
                height: $sizeMobile;
                line-height: $sizeMobile;
                text-align: center;
                border-radius: 3px;
                transform-origin: center center;
                transition: all 0.1s linear;
    
                @media ( min-width: $mobileLandscape ) {
                    width: $sizeDesktop;
                    height: $sizeDesktop;
                    line-height: $sizeDesktop;
                }
            }
            
            .label {
                position: absolute;
                top: calc( 100% + 20px );
                left: 50%;
                transform: translateX(-50%);
                margin-top: -10px;
                background-color: red;
                padding: 5px 15px;
                line-height: 16px;
                box-shadow: 0px 0px 5px rgba( 0, 0, 0, 0.3 );
                color: $white;
                font-size: 0.9rem;
                font-weight: normal;
                border-radius: 3px;
                opacity: 0;
                pointer-events: none;
                transition: all 0.2s linear;
                white-space: nowrap;
        
                &:before {
                    content: " ";
                    background-color: inherit;
                    width: 14px;
                    height: 14px;
                    transform: translate(-50%, -50%) rotate(-45deg);
                    position: absolute;
                    left: 50%;
                    top: 0px;
                }
            }
        }
    
        // Colors based on social
        &.twitter {
            .icn, .label { background-color: $twitter; }
        }
        &.facebook {
            .icn, .label { background-color: $facebook; }
        }
        &.linkedin {
            .icn, .label { background-color: $linkedin; }
        }
        &.whatsapp {
            .icn, .label { background-color: $whatsapp; }
        }
        &.e-mail {
            .icn, .label { background-color: $lightGrey; }
        }
        
        // Fancy hover show label
        a:hover, a:focus {
            .icn { transform: scale(1.2); }
            
            .label {
                opacity: 1;
                margin-top: 0px;
                z-index: 5;
                pointer-events: none;
            }
        }
    }
    
    // Hide mobile only
    @media ( min-width: $portrait ) {
        .share-item.whatsapp { display: none; }
    }
}
