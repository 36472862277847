// Persons
.person {
    display: block;
    padding: 10px 0px;
    clear: both;
    
    @include clearAfter();
    
    .profile {
        float: left;
        margin-right: 15px;
        display: block;
    }
    
    .name {
        font-weight: 300;
        font-size: 1.2rem;
        line-height: 1;
    }
    
    .date {
        margin-top: -2px;
        line-height: 1;
        font-size: 1rem;
    }
    
    .profile-info {
        width: calc( 100% - 65px );
        float: right;
    }
    
    .congratulate {
        padding: 0px 45px 0px 10px;
        position: relative;
        font-size: 0.9rem;
        
        .fa {
            position: absolute;
            right: 10px;
            top: 8px;
        }
    }
}
