//
//  Focus
//  - contains the styling for the focus on elements, when the user is tabbing
//

// Focus when user is tabbing
#page.user-is-tabbing {

    // Links & buttons
    a, button, input.button {
        &:focus {
            outline: 2px dotted $focus-outline-color;
            outline-offset: 2px;
            transition: none;
        }
    }

    // Radio & checkboxes
    input[type=radio], input[type=checkbox] {
        &:focus {
            &, & + label {
                font-weight: bold;
                outline: 2px dotted $focus-outline-color;
                outline-offset: 2px;
            }
        }
    }

    // Input, select & textarea
    input, textarea, select {
        &:focus {
            outline: 2px dotted $focus-outline-color;
            outline-offset: 2px;
            transition: none;
        }
    }

    // Invert focus color on elements with dark background
    &.dark-focus, .dark-focus {
        a, button, input.button {
            &:focus {
                outline-color: $white;
            }
        }
    }

    // Alternative to dark-focus for forces focus color on lighter backgrounds
    &.light-focus, .light-focus {
        a, button, input.button {
            &:focus {
                outline-color: $focus-outline-color;
            }
        }
    }

    // Focus on elements with image as background (slideshows)
    &.image-focus, .image-focus {
        a, button, .button {
            &:focus {
                outline-color: $white;
                background-color: $focus-outline-color;

                &, * { color: $white; }
            }
        }
    }
}
