//
//  docReader button
//  - styling for the docReader button, shown next to a file link
//
#page .docReaderButton {
    display: inline-block;
    border-radius: 3px;
    line-height: 24px;
    height: 24px;
    font-size: 0.8rem;
    border: 1px solid $softGrey;
    background-color: $f5;
    position: relative;
    padding: 0px 10px 0px 30px;
    width: 95px;
    margin: 0px;
    
    &, .fa { transition: all 0.2s linear; }
    
    &:after { display: none; } // Hode newwindow icon
    
    .fa {
        display: inline-block;
        width: 24px;
        text-align: center;
        font-size: 12px;
        position: absolute;
        left: 4px;
        top: 0px;
        height: 24px;
        line-height: 24px;
        margin: 0px;
    }
    
    &:hover {
        background-color: $backBlue;
        border-color: $blue;
        color: $blue;
        text-decoration: none;
        
        .fa { color: inherit; }
    }
    
    @media ( max-width: ( $portrait - 1px ) ) {
        width: 24px;
        padding: 0px;
        .fa { left: 0px; }
        .label { display: none; }
    }
}
