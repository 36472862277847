//
//	Search form
//
.form, .base {
	padding: 0px;
	background-color: transparent;
}

.base { border-bottom: 1px solid $softGrey; }

// Input
.input {
	position: relative;
	padding-bottom: 30px;

	.searchinput {
		border-radius: 5px;
		box-shadow: 0px 2px 0px rgba( 0,0,0,0.07);
		border: 1px solid $input-border;
		background: $white;
		width: 100%;
		padding: 6px 70px 8px 15px;
		height: 48px;
		font-size: 1.1rem;
		box-sizing: border-box;
	}
}

$submit-size: 46px;

.submit, .submit:hover, .empty {
	position: absolute;
	top: 0px;
	width: $submit-size;
	min-width: $submit-size;
	height: $submit-size;
	background-repeat: no-repeat;
	background-position: center center;
	cursor: pointer;
	overflow: hidden;
	font-size: 1.2rem;
}

.submit {
	right: 1px;
	border-radius: 0px 4px 4px 0px;
	background-color: $primary;
}

.submit, .submit:hover { margin-right: 0px; }

.empty {
	right: $submit-size;
	display: block;
}

.empty:hover { opacity: 0.7; }
