/* Form */
form { border: 0px; }

fieldset {
	border: 0px;
	margin-bottom: 15px;
}

legend {
	font-size: 1.4rem;
	margin-bottom: 10px;
}

label {
	display: block;
	clear: both;
	line-height: 3rem;
	font-size: 1rem;
	cursor: pointer;
}

input[type=text], textarea, select, input[type=password] {
	background: #ffffff;
	border: 1px solid #e0e4e5;
	line-height: 2.5em;
	font-size: 1rem;
	color: #5c626a;
}

input[type=text], input[type=password] {
	height: 2.5em;
	text-indent: 10px;
	width: 88%;
}

textarea {
	padding: 1% 2%;
	line-height: 1.6;
	width: 84%;
}

select { min-width: 180px; }

input[type=text]:focus, input[type=password]:focus, textarea:focus {
	background-color: #FFFFFF;
	border-color: #d5dee5;
}

input[type=radio], input[type=checkbox] {
	display: inline-block;
	margin-right: 10px;
	position: relative;
	top: 1px;
}

input[type=submit] {
	border: 0px;
	font-size: 1rem;
	font-family: "Helvetica Neue Light", "Helvetica Neue", "ProximaNova", sans-serif;
	line-height: 1.6;
	margin: 0px 5px;
}

input[type=text], input[type=submit] { -webkit-appearance: none; }

input[type=radio] + label, input[type=checkbox] + label {
	display: inline-block;
	line-height: 1.6;
}

input[type=radio], input[type=checkbox] { line-height: 1.6; }

/* Demo */
input[type=radio]:checked+label, input[type=checkbox]:checked+label { font-style: italic; }

/* Special classes for forms,
should be moved to other position in css file later to prevent hierarchy problems */

input.code, input.number { margin-right: 4px; }
input.code { width: 70px; }
input.number { width: 50px; }
input.place { width: 170px; }

input.error {
	background-color: #ffeded;
	border-color: #e5d5d5;
}

fieldset div.left {
	float: left;
	margin-right: 5px;
}
