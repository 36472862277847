//
//  Place reactions
//  - shows a box with the total amount of reactions and
//  -- a reaction textarea or
//  -- a login-button
//
.place-reaction {
    
    // Head
    .react-head {
        text-align: center;
        padding-bottom: 20px;
    
        h3 {
            font-weight: bold;
            font-size: 1.3rem;
            color: $mediumGrey;
            margin: 0px;
        }
        
        .sub { color: $lightGrey; }
        
        .amount {
            color: $primary;
            font-weight: bold;
        }
    }
    
    // Not logged in
    .not-logged-in {
        border-top: 1px solid $e4;
        text-align: center;
        padding-top: 30px;
        
        .button {
            padding: 3px 15px;
            margin-bottom: 5px;
        }
    }
    
    // Reaction editor holder
    .reaction-text-holder {
        position: relative;
        box-sizing: border-box;
        width: 100%;
        
        textarea {
            width: 100%;
            clear: both;
            margin: 0px;
            box-sizing: border-box;
            height: 270px;
            resize: vertical;
        }
    }
    
    .toolbar {
        margin-top: 20px;
        text-align: right;
    }
}
