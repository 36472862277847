//
//  Event tiles
//  - alternative event view, no calendar and only tiles
//

.events_tiles {
    ul {
        list-style: none;
        margin: 0px;

        @media ( min-width: $mobileLandscape ){
            display: flex;
            flex-flow: row wrap;
            margin: 20px -20px;
        }
    }

    li {
        margin: 20px;
        position: relative;
        overflow: hidden;
        height: 0px;
        padding-bottom: 32%;

        @media ( min-width: $mobileLandscape ) {
            flex: 0 0 calc(50% - 40px);
        }

        @media ( min-width: $desktop ) {
            flex-basis: calc(33.333333% - 40px);
        }

        &:hover {
            .img, .noImage { transform: scale( 1.1 ); }
        }
    }


    h3 {
        display: block;
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 4;
        padding: 10px;
        text-align: left;
        line-height: 40px;
        font-size: 14pt;

        span {
            background: #ED7F35;
            padding: 5px;
            color: #FFFFFF;
            text-decoration: none;
        }
    }

    h3 span { background: #7E8B96; }
    .active h3 span { background: #ED7F35; }
    .upcomming h3 span { background: #7E8B96; }

    .img, .noImage {
        min-width: 100%;
        min-height: 100%;
        position: absolute;
        top: 0px;
        z-index: 3;
        transform-origin: center center;
        transition: all 0.15s linear;
    }

    .img {
        position: absolute;
        top: 0px;
    }

    .noImage {
        background: #D9DEE0 url(../images/emblem_alt.svg);
        display: block;
    }

    .eventintro {
        background: #FFFFFF;
        position: absolute;
        top: 0px;
        height: 100%;
        width: 100%;
        border-bottom: 3px solid #CCCCCC;
    }

    .metadata {
        position: absolute;
        left: -9999px;
        z-index: 6;
        background: #E5F0F9;
        width: 100%;
        font-size: 10pt;
    }

    .daynr {
        display: block;
        position: absolute;
        bottom: 10px;
        right: 10px;
        font-size: 60pt;
        color: #FFFFFF;
        text-shadow: 0px 2px rgba(0, 0, 0, 0.5);
        z-index: 5;

    }

    .passed { opacity: 0.5; }
}
