//
//  List widget
//  - base of several list widgets for showing articles or news items
//  - for the widgets: newslist, articlelist, news, articles, newsintro, articleintro

.listwidget {
    li {
        line-height: 1.6;
        padding: 7px 0px 7px 15px;
    }

    .footer { text-align: right; }

    // Style specific tweaks
    &.rain, &.bigrain {
        li:not(:last-of-type) {
            border-bottom: 1px solid rgba(0,0,0,0.15);
        }
    }

    &.rain {
        &:not(.introlist) .content {
            padding: 0px;

            li { padding: 7px 15px 7px 20px; }
            li:before { left: 5px; }
        }

        .content ul { margin: 0px; }

        .footer {
            background-color: rgba(0,0,0,0.05);

            &:empty { background-color: transparent; }
        }
    }

    &.air, .cloud {
        li { border-bottom: 0px; }
    }
}
