//
//  Profile card
//  - show main profile information ( image, name, function and social media )
//
.profile-card {
    $profile-image-size: 150px;
    
    clear: both;
    margin-bottom: 25px;
    width: 290px;
    text-align: center;
    padding: 0px 25px;
    
    @include clearAfter();
    
    .profile-image { margin-bottom: 15px; }
    
    .profile-icon {
        font-size: 70px;
        border: 8px solid $softGrey;
        
        @include equalAll( $profile-image-size, 8px );
    }
    
    // Animate icon
    .profile-icon {
        transform: scale( 0.9 ) translateY( 20px );
        opacity: 0;
        animation: profile-image-in 0.3s linear 0.4s 1 forwards;
    }
    
    @keyframes profile-image-in {
        50% { opacity: 1; }
    
        to {
            transform: scale( 1 );
            opacity: 1;
        }
    }
    
    .fullname {
        font-size: 1.3rem;
        color: $mediumGrey;
        text-transform: uppercase;
        font-weight: 900;
        margin-bottom: 10px;
    }
    
    .function {
        color: $lightGrey;
        margin-bottom: 10px;
    }
    
    .edit-profile {
        margin: 25px auto;
        font-size: 0.9rem;
        
        .fa {
            color: $yellow;
            margin-left: 10px;
        }
    }
}
