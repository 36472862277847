//
//  Forum widget
//
.widget.forum {
    .icon {
        width: auto;
        padding: 0px;
    }
    
    .widget-box {
        border-width: 1px 0px 0px 0px;
        background-color: transparent;
        margin: 0px;
        padding: 10px;
        
        &:first-of-type { border-width: 0px; }
    }
    
    .profile-icon {
        
        @include equalAll(40px);
        
        @media ( min-width: 768px ) {
            @include equalAll(50px);
        }
    }
    
    .react-button {
        display: none;
        
        .label { display: none; }
        .icon {
            padding-left: 0px;
            pointer-events: none;
        }
    }
    
    .comments-total, .title-holder .title { font-size: 1rem; }
    
    .widget-footer {
        .button.clean {
            color: #37afe4;
            display: none;
            
            .icon {
                position: relative;
                margin-left: 10px;
                top: 1px;
            }
        }
        
        .button.new-message {
            display: block;
            text-align: left;
            
            .icon {
                float: right;
                margin-top: 8px;
            }
        }
    }
}

// Size variations
.single.widget.forum {
    .profile-icon {
        @include equalAll(40px);
    }
    
    .comments-total { display: none; }
    .reaction-date { display: block; }
}

.widget.forum:not(.single) {
    @media( min-width: $portrait ) {
        .widget-box { padding: 20px 10px 20px 20px; }
        
        .react-button {
            display: block;
            
            &:before { height: 30px; }
        }
        
        .comments-total, .react-button {
            &, .icon { font-size: 1.4rem; }
        }
    
        .title-holder .title { font-size: 1.2rem; }
        
        .widget-footer {
            .button.clean { display: inline-block; }
    
            .button.new-message {
                display: inline-block;

                .icon {
                    float: none;
                    margin-top: 0px;
                }
            }
        }
    }
}

// Style variations
.widget.forum.air {
    .title { color: $mediumGrey; }
}

.widget.forum.cloud {
    .widget-footer { padding-bottom: 15px; }
}

.widget.forum.bigrain {
    .widget-box {
        padding-left: 0px;
        padding-right: 0px;
    }
}
