/* Table */
table
{
	background: #FFFFFF;
	border: 1px solid #E0E4E5;
	width: 100%;
	border-collapse: collapse;
	margin: 20px 0px;
}

caption
{
	text-align: left;
	background: #F47721;
	color: #FFFFFF;
	line-height: 1.6;
	padding: 2px 4px;
	margin: 0px;
}

td , th { text-align: left; padding: 2px 4px;  }
td { vertical-align: top; border: 1px solid #E0E4E5; }
th, thead, tfoot { font-weight: bold; vertical-align: bottom; }

thead td, thead th, th { background: #CCC; }
tfoot td, tfoot th { background: #E0E4E5; font-weight: bold;}
td p { margin-bottom: 0px; }
tr:nth-child(odd) { background: #F5F5F5; }

@media print {
	thead th, thead td, th { -webkit-print-color-adjust: exact; font-weight: bold; }
}