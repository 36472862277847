.row {
    clear: both;
    position: relative;
    padding: 15px 0px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    
    @include clearAfter();
    
    &.negativeMargin { margin-top: -80px; }
    &.negativeMargin.firstRow { padding-top: 100px; }
    
}

/* Fixed height */
.fixedHeight { min-height: auto; }
.singleRow, .doubleRow  { min-height: 100px; }
.tripleRow, .quadRow { min-height: 200px; }

.singleRow .fixedHeight, .doubleRow .fixedHeight { height: 100px; min-height: auto; }
.tripleRow .fixedHeight, .quadRow .fixedHeight { height: 200px; min-height: auto; }

.row.autoHeight { min-height: auto; }

@media (min-width: 600px) {
	.singleRow, .doubleRow { min-height: 150px; }
	.tripleRow, .quadRow { min-height: 300px; }
	.singleRow .fixedHeight, .doubleRow .fixedHeight { height: 150px; }
	.tripleRow .fixedHeight, .quadRow .fixedHeight { height: 300px; }
}

@media (min-width: 760px) {
	.singleRow { min-height: 150px; }
	.doubleRow { min-height: 300px; }
	.tripleRow { min-height: 450px; }
	.quadRow { min-height: 600px; }

	.singleRow .fixedHeight { height: 150px; }
	.doubleRow .fixedHeight { height: 300px; }
	.tripleRow .fixedHeight { height: 450px; }
	.quadRow .fixedHeight { height: 600px; }
    
    .doubleRow .banner:before { padding-top: 220px; }
}

/* Imagebackground on row */
.row.rowimage {
    padding: 1% 0px;
    background: linear-gradient(#222, #666);
}

@media print {
	#wrapper .row {
	    background: transparent;
        & + .row { border-top: 1px solid #CCCCCC; }
    }
}

/* Remove padding and margins based on setting */
.row.noPadding {
    padding: 0px;
    
    .tile.full {
        margin: 0px;
        padding: 0px;
        width: 100%;
    }
}

// Row background image info
.row-background-info {
    text-align: left;
    z-index: 9;
    padding: 15px;
    position: absolute;
    right: 0px;
    bottom: 0px;
    max-width: 300px;
    
    &, * {
        color: $white;
        box-sizing: border-box;
    }
    
    .icn {
        font-size: 1.4rem;
        cursor: pointer;
    }
    
    @media ( max-width: ( $portrait ) ) {
        .info-holder {
            position: absolute;
            opacity: 0;
            right: 10px;
            bottom: 100%;
            background-color: rgba( 0, 0, 0, 0.7 );
            padding: 15px;
            min-width: 240px;
            max-width: 80vw;
            z-index: 10;
            font-size: 0.9rem;
            border-radius: 3px;
            pointer-events: none;
            transition: all 0.15s linear;
        }
    
        &:hover {
            .info-holder {
                opacity: 1;
                pointer-events: visible;
            }
        }
    }
    
    @media ( min-width: $portrait ) {
        background-color: rgba( 0, 0, 0, 0.7 );
        padding: 20px;
    
        .icn { display: none; }
    }
}
