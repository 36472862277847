body { font-family: 'Open Sans', Arial, sans-serif; }

html { font-size: 87.5%; /* 14px */ }

body {
    font-size: 1rem;
    font-family: 'Open Sans', Arial, sans-serif;
    line-height: 1.6;
}

html, body {
    color: #303f4c;
    background: #f4f7f9;
}

// Links
a { color: #F47721; }
a:active, a:hover, a:focus { text-decoration: none; }

a img { border: 0px; }
img { max-width: 100%; }
p { margin-bottom: 15px; }

// Headers
h1, h2, h3, h4, h5, caption, legend, .title { font-weight: normal; }

h1, h2, h3, h4, h5 {
    margin-bottom: 3px;
    line-height: 1.3;
}

h1 { font-size: 3rem; }
h2 { font-size: 1.6rem; }
h3 { font-size: 1.3rem; }
h4, caption { font-size: 1.2rem; }

h5 {
    font-size: 1rem;
    font-weight: bold;
}

h2 a, h3 a, h4 a, h5 a { text-decoration: none; }
h2 a:hover, h3 a:hover, h4 a:hover, h5 a :hover{ text-decoration: underline; }

ul, ol {
    margin-left: 0px;
    margin-bottom: 10px;
    margin-left: 20px;
}

@media print {
    * {
        -webkit-transition: none !important;
        transition: none !important;
    }
    
    body, html { background-color: #FFFFFF; }
    @page { margin: 20mm 15mm 15mm 15mm; }
    img { max-width: 500px; }
    
    #wrapper a[href^="http://"]:after, #wrapper a[href^="https://"]:after, #wrapper a[href^="mailto:"]:after {
        content: " ( " attr(href) " )";
        font-size: 80%;
        color: #A0AFB0;
        font-family: inherit;
    }
    
    #wrapper a[href^="#"]:after { display: none;}
    #wrapper a { word-wrap: break-word; color: inherit; }
}
