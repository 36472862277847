// Foldout
.foldout {
	background-color: $white;
	margin-bottom: 30px;

	fieldset {
		float: left;
		margin: 0px 25px 20px 25px;
		min-width: 150px;

		&:first-of-type { margin-left: 0px; }
	}

	.gosButton  { margin-right: 5px; }

	.period {
		.select-date-wrapper {
			border-top: 1px solid $e4;
			margin-top: 20px;
			padding-top: 15px;
			display: none;
		}

		.date-wrap {
			display: inline-block;
			flex-flow: row nowrap;
			align-items: center;
			position: relative;
			margin: 0px 10px 0px 0px;

			@media ( max-width: ( $mobileLandscape - 1px )) {
				display: block;
				width: 100%;
			}
		}

		.date-format-holder {
			display: none;
		}

		.date-wrap label {
			width: 30px;
			margin: 0px;
			line-height: 30px;
		}

		.datefield {
			height: 30px;
			width: 140px;
			cursor: pointer;
			margin: 0px;

			@media ( max-width: ( $mobileLandscape - 1px )) {
				width: calc( 100% - 40px );
				margin-bottom: 5px;
			}
		}

		.select-date-toolbar {
			clear: both;
			flex-flow: row nowrap;
			justify-content: space-between;
			padding: 0px 10px 0px 30px;
			display: none;
		}

		.emptydate {
			cursor: pointer;

			&:hover { text-decoration: none; }
		}

		.emptydate, .data-format {
			flex: 0 0 auto;
			font-size: 11px;
			color: inherit;
		}

		&.show-custom {
			.select-date-wrapper { display: block; }
			.select-date-toolbar { display: flex; }
		}
	}

	.toolbar {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;
		margin-top: 30px;
		clear: both;
	}

	.gosClose { margin-left: auto; }
}

// Search in (title) filter
.search-in {
	padding-top: 10px;
	clear: both;
}

// Advanced filters
.advancedFilters {
	border-top: 1px solid $softGrey;
	clear: both;

	&:empty { display: none; }

	label {
		clear: none;
		width: 75%;
	}

	fieldset {
		margin: 30px 0px;
		width: 100%;
		float: none;

		.column-wrapper {
			padding-left: 15px;
			@media (min-width: $mobileLandscape) {
				column-count: 2;
			}

			@media (min-width: $landscape) {
				column-count: 3;
			}

			@media (min-width: $desktop) {
				column-count: 4;
			}
		}
	}

	.title {
		font-weight: bold;
		margin-bottom: 15px;
	}

	.clearfix {
		width: 230px;
		float: left;
	}
}

// Animated foldout
.foldout {
	height: auto;
	max-height: 0px;
	overflow: hidden;
	transition: max-height .5s ease-in-out;

	.container { padding: 30px 0px; }
}

.foldout.active {
	max-height: 2000px;
	border-bottom: 1px solid $softGrey;
}
