//
//  Search
//  - styling for the searchpage
//

$radio-border: $input-border;
$radio-active: #488325;
$radio-size: 20px;

@import "info";

.gos {
    @import "_base";
    @import "containers";

    // Search form and filters
    @import "radio";
    @import "form";
    @import "tabs";
    @import "fold-out";

    // Results
    @import "results";
    @import "pages";

    // Right column (tagcloud?)
    @import "sidebar";
}

// RIS Hacks & fixes
@import "fixes";
