//
//  Default mixin
//
@mixin clearAfter() {
    &:after {
        content: " ";
        clear: both;
        display: block;
    }
}

@mixin equalAll( $size, $borderSize:0px ) {
    height: $size;
    line-height: ($size - ($borderSize * 2));
    width: $size;
}
