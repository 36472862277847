//
//  Color variables
//

// Status colors
$green            :    #60bc3d;
$red              :    #b70101;
$yellow           :    #ffc000;
$orange           :    #f47721;
$blue             :    #37afe4;
$lightBlue        :    #6ec3e8;

// Greys
$softGrey         :    #cdd6de;
$secondSoftGrey   :    #bec2c8;
$lightGrey        :    #a0afb0;
$mediumGrey       :    #5d6773;
$darkGrey         :    #282b2d;
$altGrey          :    #6f6f6f;

$f5               :    #f5f5f5;
$e4               :    #e4e4e4;

// GO
$primary          :    #f46b10;
$secondary        :    #a0afb0;

// Status back colors
$backGreen        :    #eaffe2;
$backRed          :    #ffe4e3;
$backYellow       :    #fff0c7;
$backBlue         :    #dbf4ff;

// Status text colors
$txtGreen         :    #435637;
$txtRed           :    #563232;
$txtYellow        :    #7f6a3b;
$txtBlue          :    #3c798e;

// RIS Custom
$headBack         :    #639bad;
$headBack         :    #7e8193;

// Zebra stripes
$odd              :    #ffffff;
$even             :    #f5f5f5;

// Standard colors
$black            :    #000000;
$white            :    #ffffff;


// Social media
$twitter          :    #33cbfc;
$linkedin         :    #0077b5;
$facebook         :    #3b5999;
$youtube          :    #d32421;
$flickr           :    #ff707d;
$whatsapp         :    #25d366;
$instagram        :    #e4405f;

$primary-darker:                        #f46b10; // Somewhat darker orange, used in headings

// text color
$text-color-base:                       $darkGrey;

// links
$link-color:                            $primary;
$link-color-hover:                      $secondary;

// Focus
$focus-outline-color:                   #1472E6;

// Colors
$hover-blue: $focus-outline-color;
$active-blue-border: #37aee3;
$active-blue-bg: #daf3ff;

// PDF Red
$pdf-red        :   #eb1c29;

// Color array
$colors : (
        'orange'		( $orange ),
        'blue'			( $blue ),
        'green'			( $green ),
        'yellow'		( $yellow ),
        'red'			( $red ),
        'grey'			( $lightGrey ),
        'primary'       ( $primary ),
        'secondary'     ( $secondary )
);

//
//  Form
//
$fieldset-border: $softGrey;
$input-border: #7e97a9;
