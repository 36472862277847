//
// Index of pages / alphabet
//
.pages, .alphabet { margin-bottom: 20px; text-align: center; }
.alphabet { text-align: left; }

.pages .curPage { font-size: 0.9rem; margin-bottom: 0px; }
.pages .curPage strong { font-size: 1.1rem; }
.pages a, .pages a:link, .pages .current,
.alphabet a, .alphabet a:link, .alphabet .current
{
	display: inline-block;
	text-decoration: none;
	border: 1px solid #CCCCCC;
	background: #E0E4E5; /* TODO Set color on different place */
	text-align: center;
	padding: 2px 4px;
	min-width: 16px;
	font-size: 0.9em;
	color: #000000;
	margin-bottom: 2px;
}

.pages .current,
.alphabet .selected
{
	background: #F47211; /* TODO Set color on different place */
	border: 1px solid #F47211; /* TODO Set color on different place */
	color: #FFFFFF;
}

.alphabet a.more
{
	clear: both;
	margin-top: 10px;
}
