//
//	Search results
//

// Hide some unwanted stoff
.title {
	.resultnumber, .resultlabel { display: none; }
}

// Search helpers: number of results | warning message | search suggestion

.numberofresults, .suggestion, .warning {
	clear: both;
	margin-bottom: 15px;

	strong { font-weight: bold; }
}

.suggestion {
	font-size: 18px;
	margin-bottom: 30px;

	a { font-style: italic; }
}

.warning { font-style: italic; }

/* base result */
.results {
	width: 100%;

	ul { list-style: none; }

	li {
		margin: 40px 0px;
		position: relative;
	}

	.title {
		display: block;
		text-decoration: none;
		padding-right: 50px;

		h3 {
			font-size: 1.3rem;
			line-height: 1.6;
			font-weight: bold;
			color: $darkGrey;
			margin: 0px;
			transition: all 0.15s linear;


			.icn-right {
				font-size: 1rem;
				display: inline-block;
				margin-left: 10px;
			}
		}

		&:hover {
			h3 { text-decoration: underline; }
			.search-pointer { color: $orange; }
		}

		&:visited {
			h3, .url { color: $mediumGrey; }
		}
	}

	.url {
		display: block;
		text-decoration: none;
		font-size: 0.9rem;
		word-break: break-all;
		word-wrap: break-word;
		line-height: 1.4;
		font-style: normal;
	}

	.search-pointer {
		display: block;
		position: absolute;
		right: 3px;
		top: 3px;
		width: 40px;
		height: 40px;
		line-height: 40px;
		font-size: 1.6rem;
		text-align: center;
		color: $softGrey;
		transition: all 0.15s linear;
	}

	.preview {
		margin: 10px 0px;
		font-size: 1rem;
		position: relative;

		.more-button {
			position: absolute;
			right: 0px;
			bottom: 0px;
			background:  linear-gradient( to right, #fff0 0px, #fff 30px );
			color: $focus-outline-color;
			border: 0px;
			font: inherit;
			padding-left: 30px;
			text-decoration: underline;
			cursor: pointer;

			span { pointer-events: none }
			.less-txt { display: none; }

			&:hover, &:focus {
				color: inherit;
			}
		}

		&.active {
			.more-txt { display: none; }
			.less-txt { display: inline; }
		}

		&:not(.active) {
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;


		}

		.tag { font-weight: bold; color: $black; }
	}

	.metadata {
		display: block;

		a { display: block; }

		.small {
			display: none;
			margin-top: 2px;

			.label {
				font-weight: bold;
				display: inline-block;
				min-width: 120px;
			}

			&.meeting, &.doctype {
				display: block;
				color: inherit;
			}

			&.meeting {
				display: flex;
				flex-flow: row nowrap;

				.label { flex: 0 0 120px; }
				.meeting-links { flex: 1 1 auto; }
			}
		}
	}
}

// To top button
.gosTop {
	width: 50px;
	float: right;
	display: none;

	&.fixed {
		position: fixed;
		right: 15px;
		bottom: 15px;
		display: block;
		z-index: 15;

		@media (min-width: 600px){
			display: none;
		}
	}
}
