/* Right column */
@media (min-width: 768px){
	.results { float: left; margin-right: 30px; }
	.rightcolumn { float: left; max-width: 198px; }
}

.block {
	border: 1px solid $e4;
	padding: 15px;
	background: $f5;
	margin-bottom: 15px;

	ul { padding-left: 15px;}
}

// Tagcloud
.cloud {
	display: none;

	ul {
		box-sizing: border-box;
		padding: 0px;
	}

	li {
		display: inline-block;

		a {
			display: inline-block;
			padding: 5px;
			text-decoration: none;
			color: $mediumGrey;

			&:active, &:hover { text-decoration: underline; }
		}

		&, &.step1 { font-size: 100%; }
		&.step2 a { font-size: 120%; }
		&.step3 a { font-size: 130%; }
		&.step4 a { font-size: 140%; }
		&.step5 a { font-size: 160%; }
	}
}

// Compared (TODO: Waar word dit gebruikt?)
.compared ul a { line-height: 1.4; }
