/* Suggestive search with typeahead */
.searchSuggestions
{
display: none;
position: relative;
box-sizing: border-box;
background: #FFFFFF;
box-shadow: 0px 0px 15px rgba( 0, 0, 0, 0.15 );
padding: 10px;
position: absolute;
z-index: 8;
min-width: 260px;
max-width: 800px;
min-height: 50px;
}

.searchSuggestions.active { display: block; }

.searchSuggestions:after
{
content: " ";
clear: both;
display: block;
}

.searchSuggestions .suggestItem
{
display: block;
padding: 15px 50px 15px 15px;
text-decoration: none;
line-height: 1.2;
position: relative;
box-sizing: border-box;
}

.searchSuggestions .suggestItem:hover { background: #F5F5F5; }
.searchSuggestions .suggestItem+.suggestItem { border-top: 1px solid #E4E4E4; }

.searchSuggestions .suggestItem:after
{
content: "\f105";
font-family: $font-awesome;
position: absolute;
right: 15px;
color: $softGrey;
font-size: 24px;
line-height: 40px;
top: 50%;
margin-top: -20px;
}

.searchSuggestions .suggestItem .name
{
font-size: 1rem;
font-weight: bold;
display: block;
clear: both;
}


.searchSuggestions .suggestItem .desc
{
font-size: 0.9rem;
color: $lightGrey;
white-space: nowrap;
text-overflow: ellipsis;
overflow: hidden;
height: 18px;
display: block;
}

.searchSuggestions .suggestSpinner,
.searchSuggestions .noResults
{
display: block;
text-align: center;
color: $lightGrey;
}

.searchSuggestions .suggestSpinner { font-size: 36px; margin: 40px 0px; }
