.menuList { border: 0px; }
.menuList ul { list-style: none; margin: 0px; }
.menuList li { position: relative; }
.menuList a, .menuList a:link { text-decoration: none; }

/* Level 1 */
.menuList a, .menuList a:link
{
	display: block;
	border-top: 1px dotted rgba(0,0,0,0.2);
	font-size: 1.1rem;
	padding: 12px 10px;
	margin: 0px 10px;
	line-height: 1.3;
}

/* Level 2 */
.menuList li li a, .menuList li li a:link { font-size: 1rem; padding: 6px 10px; margin-left: 15px; }

/* Other levels */
.menuList ul ul ul { background: rgba(0,0,0,0.05);  }
.menuList li li li a, .menuList li li li a:link
{
	font-size: 0.9rem;
	line-height: 2;
	padding: 3px 10px;
	border-top: 0px;
	margin-left: 20px;
}

.menuList ul ul ul ul { background: transparent; margin-left: 20px; }

/* Show parent, haschildren and active */
.menuList .haschildren:after,
.menuList .parent:after
{
	color: #F47721;
	content: "\e6d0";
	font-family: 'defaultIcons';
	font-size: 1.2rem;
	float: right;
}

.menuList .parent:after, .menuList .active.haschildren:after { content: "\e6ce"; }
.menuList a.active { color: #F47721; }

/* Remove last border */
.menuList > ul >  li:first-of-type a { border-top: 0px; }

.menuList.clean ul ul ul { background: transparent;  }

/* #32289 Added hover */
.menuList a:hover
{
	opacity: 0.6;
	text-decoration: underline;
}