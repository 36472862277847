//
//  Widget base
//

.widget {
    position: relative;
    box-sizing: border-box;

    @include clearAfter();

    .overlay { height: 100%; }
}
