.cake-holder {
    @include equalAll( 100px );
    
    margin: auto;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    text-align: center;
    
    .cake-icon, .cake-hole {
        position: absolute;
        transform-origin: center center;
        left: 50%;
        margin-left: -40px;
        bottom: 0px;
        width: 80px;
        display: block;
    }
    
    .cake-icon-overflow {
        width: 100px;
        height: 80px;
        bottom: 20px;
        overflow: hidden;
        position: absolute;
        left: 0px;
    }
    
    .cake-icon {
        color: $yellow;
        font-size: 50px;
        height: 60px;
        line-height: 60px;
        animation: cake-icon 1s linear 0.2s 1 forwards;
        z-index: 6;
        
        // Animation start
        transform: rotate(-25deg);
        bottom: -80px;
    }
    
    .cake-hole {
        border-radius: 100%;
        background-color: #888888;
        animation: cake-hole 1.5s linear 0s 1 forwards;
        bottom: -20px;
        z-index: 4;
        height: 80px;
        
        // Animation start
        opacity: 0;
        transform: scale( 0, 0 );
    }
}

//
//  Animations
//
@keyframes cake-hole {
    
    // In
    0% {
        opacity: 0;
        transform: scale( 0, 0 );
    }
    
    15% { opacity: 1; }
    20% { transform: scale( 1.1, 0.3 ); }
    
    // Wait
    30% { transform: scale( 1, 0.2 ); }
    80% { opacity: 1; }
    
    // Out
    90% { transform: scale( 1, 0.2 ); }
    
    100% {
        opacity: 0;
        transform: scale(0, 0);
    }
}

@keyframes cake-icon {
    
    // Wait
    0% {
        transform: rotate(-25deg);
        bottom: -80px;
    }
    
    // In
    40% {
        transform: rotate(-25deg);
        bottom: -80px;
    }
    
    60% {
        transform: rotate(10deg);
        bottom: 10px;
    }
    
    80% {
        transform: rotate(0deg);
        bottom: 0px;
    }
    
    100% {
        transform: rotate(0deg);
        bottom: 0px;
    }
}
