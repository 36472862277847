/* ############ */
/* #   List   # */
/* ############ */
.block .list, .toggleblock .list { margin-bottom: 0px; }

ul.list { margin-left: 0px; list-style: none; }

.list li { line-height: 2; padding-left: 15px; position: relative; }
ul.list li:before
{
content: "\f054";
font-family: $font-awesome;
position: absolute;
margin-right: 5px;
float: left;
left: 0px;
font-weight: bold;
}

.list.buttonList li:before { display: none; }
.buttonList .button { margin-bottom: 10px; }

.list a, .list a:link, .light .list a, .light .list a:link, .list a:visited { text-decoration: none; color: #303F4C; }
.light .list .dark a, .light .list .button.dark, .list .button.dark { color: #FFFFFF; }

.list.column { float: left; min-width: 120px; margin-right: 2%; width: 98%; }

.threeColumns .list.column { width: 31%; }
.twoColumns .list.column { width: 48%; }
.fourColumns .list.column { width: 23%; }

.list a:hover
{
text-decoration: underline;
}
