/* Style default radiobuttons */




	input[type=radio], input[type=checkbox] {
		overflow: hidden;
		width: 1px;
		height: 1px;
		opacity: 0;
		pointer-events: none;
		position: absolute;
		left: 0px;
		top: 0px;

		& + label {
			float: none;
			width: auto;
			cursor: pointer;
			font-style: normal;
			clear: both;
			display: block;
			position: relative;
			line-height: 20px;
			padding: 2px 0px;
			margin: 0px 0px 0px 1px;

			&:before {
				display: block;
				float: left;
				clear: left;
				width: $radio-size;
				height: $radio-size;
				line-height: $radio-size;
				border-radius: 3px;
				border: 1px solid $radio-border;
				content: " ";
				margin-right: 10px;
				position: relative;
				background-color: $white;
				transition: 0.15s ease-in-out;
				box-sizing: border-box;
				color: $white;
				text-align: center;
			}
		}

		&:checked + label {
			font-weight: bold;
			font-style: normal;

			&:before {
				background-color: $radio-active;
			}
		}
	}

	// Radio buttons specific
	input[type=radio]+label:before {
		border-radius: 20px;
		border: 3px solid $white;
		box-shadow: 0 0 0 1px $radio-border;
		left: 1px;
	}

	// Checkboxes specific
	input[type=checkbox] {
		& + label:before {
			content: "\f00c";
			font-family: $font-awesome;
			font-weight: bold;
		}

		&:checked + label {
			&:before {
				border-color: $radio-active;
			}
		}
	}

