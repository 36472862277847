//
//  Social links
//
.social {
    margin-top: 25px;
    clear: both;
    
    &, & * {
        position: relative;
        box-sizing: border-box;
    }
    
    a {
        display: inline-block;
        margin: 4px;
        border-radius: 100%;
        background-color: $softGrey;
        color: $white;
    
        @include  equalAll( 30px );
        
        .fold {
            opacity: 0;
            position: absolute;
            pointer-events: none;
            padding: 10px;
            border-radius: 3px;
            box-shadow: 0px 3px 0px rgba( 0, 0, 0, 0.1 );
            z-index: 5;
            background-color: red;
            left: 50%;
            top: calc( 100% + 20px );
            transform: translateX(-50%);
            font-size: 0.9rem;
            transition: all 0.2s ease-in;
            min-width: 120px;
            max-width: 200px;
            line-height: 1;
            
            &:before {
                content: " ";
                position: absolute;
                width: 14px;
                height: 14px;
                background-color: blue;
                top: -5px;
                left: 50%;
                margin-left: -7px;
                transform: rotate( -45deg);
            }
        }
        
        &:hover .fold {
            opacity: 1;
            top: calc( 100% + 10px );
        }
        
        // Social media colored
        &.twitter {
            &, .fold, .fold:before { background-color: $twitter; }
        }
        
        &.linkedin {
            &, .fold, .fold:before { background-color: $linkedin; }
        }
    
        &.facebook {
            &, .fold, .fold:before { background-color: $facebook; }
        }
        
        &.youtube {
            &, .fold, .fold:before { background-color: $youtube; }
        }
        
        &.email {
            &, .fold, .fold:before { background-color: $primary; }
        }
        
        &.other {
            &, .fold, .fold:before { background-color: $secondary; }
        }
    }
}
