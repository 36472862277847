//
// Base layout
//
& {
    max-height: 90vh;
    position: relative;
    overflow: hidden;
}

&, div, .slide {
    position: relative;
    box-sizing: border-box;
}


// Holder for titlebar & slider combi
.holder {
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-flow: column nowrap;
}

.titlebar {
    flex: 0 0 auto;
}

// Holder for slides, with overflow to hide slides

.slider, .slide {
    background-color: $darkGrey;
}

.slider {
    flex: 1 1 auto;
}

// Individual slides
.slide {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 100%;
    visibility: hidden;
    
    // Slide a A-tag
    color: inherit;
    text-decoration: none;
    
    // Active
    &.active {
        left: 0%;
        visibility: visible;
    }
    
    // Background settings
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    
    // Default image
    &.noImage {
        background-image: url('../images/newsholder.jpg');
    }
}

