//
//  Events widget
//  - shows a list of upcoming or past events
//
.widget.eventsList {
    .event {
        text-decoration: none;
        clear: both;
        padding: 7px 0px;
        box-sizing: border-box;
        display: block;
        border-radius: 3px;
        
        &:hover { background-color: $f5; }
        
        &:after {
            content: " ";
            clear: both;
            display: block;
        }
    }

    .date {
        font-size: 24px;
        color: inherit;
        text-align: center;
        display: block;
        width: 50px;
        line-height: 1;
        box-sizing: border-box;
        float: left;
        margin-right: 10px;
        
        abbr {
            font-size: 0.9rem;
            display: block;
            clear: both;
            text-decoration: none;
            margin-top: 3px;
            color: $mediumGrey;
        }
    }
    
    .itemcontent {
        float: right;
        width: calc( 100% - 60px );
        
        .time {
            color: $lightGrey;
        }
        
        .description {
            margin-top: 10px;
            color: $mediumGrey;
        }
    }
    
    // Style specifics
    &.dark {
        .event:hover { background-color: rgba( 255, 255, 255, 0.3 ); }
    }
    
    &.rain {
        .event {
            padding: 10px;
            border-bottom: 1px solid $e4;
        }
    }
    
    &.bigrain {
        .date {
            margin-top: 4px;
            border-right: 1px dotted $e4;
            width: 60px;
            margin-right: 20px;
            
            .nr {
                font-weight: bold;
                font-family: 'Oswald', sans-serif;
            }
        }
    
        .itemcontent { width: calc(100% - 80px); }
    }
    
    // Transparent row
    .bgColorTransparent & {
        .event:hover { background-color: rgba( 0, 0, 0, 0.03 ); }
    }
}
