//
//  Options
//  - contains specific styling or tweaks based on configurable options in the widget
//

// Hide navigation
&.hideNavigation
{
    .controls, .steps, .move { display: none; }
}

// Autoplay
&:not(.autoplay) .controls .pause { display: none; }

// Darken slides
&.darkenSlides
{
    .slide:before
    {
        position: absolute;
        left: 0px;
        top: 0px;
        right: 0px;
        bottom: 0px;
        background-color: #000000;
        content: " ";
        display: block;
        opacity: 0.5;
        transition: opacity 0.3s ease-in;
    }
    
    &:hover .slide:before { opacity: 0.2; }
}
