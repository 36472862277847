//
//  Default classes
//  - contains some generic default classes
//

// Hide classes
#page {
	.nonvisual, .hidden, .hide, .highlighted {
		position: absolute;
		left: -9999vw;
		width: 1px;
		height: 1px;
		overflow: hidden;
	}
}

// Forced clearing
.clearer, .clear-after:after {
    display: block;
    height: 0px;
    font-size: 0px;
    clear: both;
}

.clearer { width: 100%; }
.clear-after:after { content: " "; }

//
//  Skip links
//  - default hidden, shown on focus
//
.skip {
    position: absolute;
    transform: translate(-50%,-150%);
    left: 50%;
    top: 0px;
    box-shadow: 0px 0px 5px rgba( $black, 0.4 );
    color: $white;
    background-color: $focus-outline-color;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 0px 0px 5px 5px;
    padding: 7px 20px;
    text-decoration: none;
    z-index: 25;
    transition: all 0.15s linear;

    &:focus { transform: translate(-50%,0%); }
}

.line {
	display: block;
	height: 1px;
	font-size: 0px;
	clear: both;
	background: #d7dade;
	margin: 2%;
	width: 96%;
}

p, img, .imgLabel, table {
	&.left {
		float: left;
		margin-right: 10px;
	}
	
	&.right {
		float: right;
		margin-left: 10px;
	}
}

table {
	&.left, &.right {
		width: auto;
		margin-top: 5px;
	}
	
	&.left { margin-right: 30px; }
	&.right { margin-left: 30px; }
}

.left { text-align: left; }
.right { text-align: right; }
.centered { text-align: center; }

.img_align_left { float: left; margin-right: 10px; }
.img_align_right { float: right; margin-left: 10px; }

span.imgLabel.center {
	display: table;
	margin: auto;
	
	img { margin: auto; }
}

.imgLabel {
	background: $white;
	padding: 2%;
	display: inline-block;
	font-size: 0.8em;
	text-align: center;
	max-width: 96%;
	min-width: 100px;
	
	@media print { border: 1px solid $e4; }
	
	img {
		width: auto;
		clear: both;
		display: block;
		vertical-align:middle;
		margin-bottom: 10px;
	}
	
	img+br {
		font-size: 1px;
		height: 1px;
		display: block;
	}
}

.noContent {
	color: $mediumGrey;
	margin: 10px 0px 20px 0px;
	
	&:before {
		display: inline-block;
		content: "\65";
		font-family: "defaultIcons";
		border-radius: 30px;
		border: 1px solid #CCCCCC;
		font-size: 0.7rem;
		padding: 2px;
		text-align: center;
		width: 16px;
		margin-right: 10px;
	}
}

.meta {
	font-size: 0.9rem;
	color: #707070;
	font-style: italic;
	margin-right: 5px;
}

.small, .date, .time {
	font-size: 0.9rem;
	color: #707070;
}

.description { font-size: 0.9rem; }
.small { font-style: italic; }

a.more, span.more {
	display: inline-block;
	position: relative;
	text-decoration: none;
	
	&:hover { text-decoration: underline; }
	
	&:after {
		content: "\f054";
		font-family: $font-awesome;
		position: relative;
		top: 1px;
		padding-left: 5px;
		display: inline-block;
		font-weight: bold;
	}
}

.moreblock {
	margin-top: 30px;

	h3 { font-weight: bold; }
}

// Add newwindow icon
.newwindow {
	&.noImage .icn { display: none; }
}

/* ######################### */
/* #   Overwrite | Fixes   # */
/* ######################### */

// Margin fixes
.extraMargin {
	margin-top: 3%;
	margin-bottom: 3%;
}

.extraPadding {
	padding-top: 3%;
	padding-bottom: 3%;
}

.noMargin { margin: 0px; }

// Background fixes
.line.light { border: 0px; }

// FirstTitle
.firstTitle {
	margin: 0px 2%;
	
	@media (min-width: 700px) {
		margin: 0px;
	}
}

// MarginTitle (opposite of firstTitle)
.marginTitle { margin: 0px 2%; }

/* ############ */
/* #   Logo   # */
/* ############ */
.logo {
	background-position: left top;
	background-repeat: no-repeat;
	
	a { text-decoration: none; }
}

//
//    Some border bottom color
//
.borderTop { border-top: 1px solid #f6bf9a; }
.borderBottom { border-bottom: 1px solid #f6bf9a; }

//
//  Image & Thumb
//
.thumb, .image {
    display: inline-block;
    min-height: 40px;
    position: relative;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-color: $f5;
    transition: all 0.3s linear;
}

.image { display: block; }

.thumb, .image
{
    &.noImage {
        background-image: url(../images/emblem_alt.svg);
        background-size: auto 40%;
        background-color: $primary;
    }
}

//
//	Relative height for thumbs
//	ar[x]x[y]		aspect ratio x times y
//
.ar16x9,
.ar4x3,
.ar1x1,
.ar2x1,
.ar3x1,
.ar3x4 {
    position: relative;
    width: 100%;
}

.ar16x9:before,
.ar4x3:before,
.ar1x1:before,
.ar2x1:before,
.ar3x1:before,
.ar3x4:before {
    content: " ";
    display: block;
    transition: all 0.3s linear;
}

.ar16x9:before 	{ padding-top: 56.25%;		}
.ar4x3:before 	{ padding-top: 75%;			}
.ar1x1:before 	{ padding-top: 100%;		}
.ar2x1:before 	{ padding-top: 50%; 		}
.ar3x1:before 	{ padding-top: 33.333333%;	}
.ar3x4:before 	{ padding-top: 133.333333%;	}

//
//	Relative height to viewport
//
.widget.vh100, .vh100 { height: 100vh; }
.widget.vh90, .vh90 { height: 90vh; }
.widget.vh80, .vh80 { height: 80vh; }
.widget.vh70, .vh70 { height: 70vh; }
.widget.vh60, .vh60 { height: 60vh; }
.widget.vh50, .vh50 { height: 50vh; }


// Image header element
// - used in news widgets and news overview
.imageheader {
	width: 100%;
	margin-bottom: 10px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center top;
	display: block;
	position: relative;
	box-sizing: border-box;
	
	&:before {
		content: " ";
		display: block;
		padding-top: 30%;
	}
	
	&.noImage {
		background-color: $e4;
		background-image: url(../images/default.svg);
		background-size: auto 60%;
		background-position: center center;
	}
}


// Default icn classes
.icn {
	display: inline-block;
	text-align: center;
}

.icn-left { margin-right: 5px; }
.icn-right { margin-left: 5px; }
