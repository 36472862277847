//
//  Styling for upload files option
//
.upload-attachments {
    background-color: $f5;
    padding: 15px 20px;
    
    h2 {
        font-size: 1rem;
        font-weight: bold;
    }
    
    .info {
        position: relative;
        
        .icon {
            color: $blue;
            text-align: center;
            margin: 0px;
            cursor: pointer;
            
            @include equalAll(30px);
            
        }
    
        .requirements {
            font-size: 0.8rem;
            position: absolute;
            right: -3px;
            top: 30px;
            background-color: $white;
            border-width: 1px;
            z-index: 5;
            padding: 7px 15px;
            width: 200px;
            border-radius: 3px;
            opacity: 0;
            margin-top: -10px;
            transition: all 0.2s ease-in;
            pointer-events: none;
            
            
            &, &:before {
                background-color: $white;
                border-color: $e4;
                border-style: solid;
            }
            
            strong {
                display: block;
            }
            
            &:before {
                position: absolute;
                right: 10px;
                top: -11px;
                
                transform: rotate(-45deg);
                width: 20px;
                height: 20px;
                border-width: 1px 1px 0px 0px;
                
                content: " ";
            }
        }
        
        
        &:hover .requirements {
            opacity: 1;
            margin-top: 0px;
            pointer-events: auto;
        }
        
    }
    
    input[type='file'] {
        width: calc( 100% - 40px );
    }
    
    
}

.upload-box {
    clear: both;
    display: block;
    box-sizing: border-box;
    position: relative;
    margin: 20px 0px;
    
    h3 {
        font-size: 0.9rem;
        font-weight: bold;
    }
    
    .attachement {
        $iconsize: 24px;
        
        display: flex;
        position: relative;
        color: inherit;
        text-decoration: none;
        
        .icon, .icnbutton {
            flex: 0 0 $iconsize;
            text-align: center;
            margin: 0px;
            line-height: $iconsize;
        }
        
        .name {
            flex: 1 1 auto;
            word-break: break-all;
        }
        
        .icnbutton {
            background-color: transparent;
            border: 0px;
            border-radius: 3px;
            color: $lightGrey;
            font-size: 1rem;
            cursor: pointer;
            
            &:hover {
                background-color: $f5;
            }
        }
    }
}

.upload-box.selected-attachments {
    display: none;
    
    &.active { display: block; }
    .icnbutton:hover { background-color: $e4; }
}
