//
// Animations
//

// Move animation
&.moving
{
    .slide { animation: moveSlide linear 0.35s 1 forwards; }
    .slide.leftOut { animation-name: leftOut; }
    .slide.rightOut { animation-name: rightOut; }
    .slide.leftIn { animation-name: leftIn; }
    .slide.rightIn { animation-name: rightIn; }
}

@keyframes leftOut {
	from {
		left: 0%;
		visibility: visible;
	}
	to {
		left: -100%;
		visibility: hidden;
	}
}

@keyframes rightOut {
	from {
		left: 0%;
		visibility: visible;
	}
	to {
		left: 100%;
		visibility: hidden;
	}
}

@keyframes leftIn {
	from {
		left: -100%;
		visibility: hidden;
	}
	to {
		left: 0%;
		visibility: visible;
	}
}

@keyframes rightIn {
	from {
		left: 100%;
		visibility: hidden;
	}
	to {
		left: 0%;
		visibility: visible;
	}
}


// Fade animation
&.fading
{
    $fadeTime	:	2s;
    .slide { transition: opacity $fadeTime ease-in-out $fadeTime; opacity: 0; left: 0; z-index: 3; }
    .slide.active { opacity: 1; z-index: 4; transition-delay: 0s; }
}
