//
//  Quicklinks
//  - shows a list of links, optional in coumns
//
.quicklinks {
    container: quicklinks / inline-size;

    ul { margin-bottom: 0px; }

    li {
        font-size: 1.1rem;
        line-height: 1.4;
        margin-bottom: 5px;
        padding-bottom: 5px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        break-inside: avoid-column;

        &:before { display: none; }
        &:hover { opacity: 0.75; }

        // Hover
        a:hover { text-decoration: none; }
        li:hover { background-color: rgba(0, 0, 0, 0.05); }

        // On print the URL is shown after the text (by default).
        // Put this link on a new line
        @media print {
            a:after {
                line-height: 1;
                clear: both;
                display: block;
            }
        }
    }

    .content { padding-top: 20px; }
    .has-columns { column-count: var(--_count); }

    // Show columns in widget based on available space
    @container quicklinks ( min-width: 400px ){
        [data-columns="2"],
        [data-columns="3"],
        [data-columns="4"]
        { --_count: 2; }
    }

    @container quicklinks ( min-width: 600px ){
        [data-columns="3"],
        [data-columns="4"]
        { --_count: 3; }
    }

    @container quicklinks ( min-width: 800px ){
        [data-columns="4"]
        { --_count: 4; }
    }
}

// Style specifics
.air .quicklinks li {
    font-size: 1.2rem;
}

// Fix color of headers in rows with background-color
.dark-focus .air .fgColorA {
    color: inherit;
}
