//
//  Generic tab component
//

$tab-bg: #e0e4e5;

.tabs, .subtabs {
	clear: both;
	margin-bottom: 3px;

	a, span {
		box-sizing: border-box;
		text-decoration: none;
	}

	ul, & {
		list-style: none;
		margin: 0px;
		position: relative;

		@include clearAfter();
	}

	li {
		display: block;
		float: left;
	}

	.number { padding: 0px; color: $darkGrey; }
}

// Default styling
.tabs {
	border-bottom: 1px solid $tab-bg;

	ul { z-index: 8; }

	a, span {
		display:inline-block;
		text-decoration: none;
		padding: 7px 30px;
		border-radius: 7px 7px 0px 0px;
		background: $tab-bg;
		top: 5px;
		position: relative;
		color: #303F4C;
		z-index: 3;
		margin-right: -4px;

		&:hover {
			z-index: 5;
			background-color: $white;
		}

		&:active {
			background: #EBEFF0;
		}
	}

	.active a, span {
		background: $white;
		color: $darkGrey;
		border-bottom: 1px solid $tab-bg;
		box-sizing: border-box;
		top: 1px;
		padding: 8px 20px;
		opacity: 1;
		z-index: 10;
	}

	.inactive a, span {
		opacity: 1;
		pointer-events: none;
	}
}

.subtabs {
	border-top: 1px solid $tab-bg;
	background: $white;
	z-index: 9;

	a, span {
		padding: 4px 10px;
		top: 0px;
		border:0px;
		border-radius: 0px;
		background: transparent;

		&:hover {
			background-color: $f5;
		}
	}

	.active {
		a, span { background: $f5; }
	}
}


// In widgets
.widget {
	.tabs {
		border-bottom: 0px;

		a, span {
			padding: 5px 15px;
			line-height: 30px;
			text-align: center;
			min-width: 70px;
			font-size: 0.86rem;
			cursor: pointer;
			text-decoration: none;
		}

		.active {
			font-size: 1rem;
			font-weight: bold;
			color: $primary;
		}

		.inactive { color: rgba(0,0,0,0.3); }
	}


	// Style spefic
	&.air {
		.tabs {
			a, span {
				border-bottom: 3px solid rgba(0,0,0,0.3);
				background-color: transparent;
				font-size: 1.15rem;
			}

			.active {
				border-color: $primary;
				font-weight: normal;
			}

			.inactive { border-color: rgba(0,0,0,0.15) }
		}
	}

	&.cloud {
		.tabs {
			margin-bottom: -4px;

			a, span {
				border-radius: 5px 5px 0px 0px;
				border-right: 2px solid rgba(0,0,0,0.07);
				border-bottom: 2px solid rgba(0,0,0,0.07);
				box-sizing: border-box;
			}

			.active {
				background: $white;
				border-bottom: 4px solid $white;
				position: relative;
				top: -4px;
				line-height: 35px;
			}
		}
	}

	&.rain {
		.tabs {
			background-color: #f4f4f4;
			width: 100%;

			a { border-left: 1px solid rgba( 0,0,0,0.05); }

			.active {
				background: $primary;
				color: $white;
			}
		}
	}

	&.bigrain {
		.tabs {
			margin-bottom: -4px;
			z-index: 6;

			a, span {
				box-shadow: inset 0px -15px 15px -15px rgba(0, 0, 0, 0.1);
			}

			.active {
				background: $white;
				border-bottom: 4px solid $white;
				position: relative;
				top: -4px;
				line-height: 35px;
				font-family: oswald-bold;
				font-weight: normal;
				box-shadow: none;
			}
		}
	}
}
