.message
{
	border-radius: 5px;
	display: block;
	position: relative;
	background: #FFFFFF;
	box-shadow: 0px 3px 0px rgba( 0, 0, 0, 0.12);
	padding: 3%;
	margin: 7px 0px 10px 0px;
}

.message p { margin-bottom: 5px; }

.message:before
{
	font-size: 2.6rem;
	margin-top: 0px;
	line-height: 3rem;
	margin-left: 5px;
}

.message.notice:before, .message.notice .icon, .alert.notice:before { color: #3C853A; }
.message.warning:before, .message.warning .icon, .alert.warning:before { color: #FFA800; }
.message.error:before, .message.error .icon, .alert.error:before { color: #D00000; }

.message.dark:before, .message.dark .icon, .alert.dark:before { color: #FFFFFF; }

/* Pointer */
.message:after
{
	width: 20px;
	height: 20px;
	content: " ";
	background: #FFFFFF;
	transform: rotate(45deg);
	position: absolute;
	top: -10px;
	left: 50%;
	margin-left: -10px;
}

fieldset .message { max-width: 82%; }

.alert
{
	clear: both;
	margin: 5px;
	border-bottom: 1px solid #E4E4E4;
}

.alert:before
{
	content: "\e687";
	font-family: 'defaultIcons';
	margin-right: 5px;
	float: left;
	margin-top: -1px;
}