//
//  Events
//  - overview of events category view, with longCalendar
//

.eventGrid {
    position: relative;
    margin-bottom: 2%;

    .tile { width: 49%; margin-left: 0px; }
}

.events {
    .dayLabel { font-weight: bold; }

    .eventList {
        clear: both;
        margin-left: 0px;
        list-style: none;
    }

    .periodTitle {
        clear: both;
        border-bottom: 1px solid #E0E4E5;
        margin-bottom: 0px;
        padding-bottom: 10px;
    }

	.item {
		.title {
			font-weight: bold;
			font-size: 1.1em;
			display: block;
			text-decoration: none;
			margin: 0px;
		}

		.description { font-size: 0.8rem; color: $darkGrey; }

		.time {
			text-shadow: none;
			background: #F47721;
			line-height: 26px;
			padding: 0px 3px;

			.icn {
				position: relative;
				color: $white;
			}
		}

		.img {
			margin-top: 0px;
			z-index: 1;
		}

		.img .noImage {
			background-image: url(../images/eventholder.jpg);
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center top;
			display: block;
			position: relative;
			box-sizing: border-box;

			&:before {
				content: " ";
				display: block;
				padding-top: 66%;
			}

			.rssevent {
				background-image: url(../images/rssholder.jpg);
			}
		}
	}

	.todaylist {
		margin: 20px 0px;
	}

	.daynumber {
		border: 4px solid #bccedd;
		background: $white;
		border-radius: 100%;
		position: absolute;
		left: -34px;
		text-align: center;
		font-weight: 700;
		font-family: Arial, sans-serif;
		font-size: 1.8rem;
		@include equalAll(60px);

		@media ( max-width: ( $portrait - 1px )) {
			font-size: 1.4rem;
			left: -25px;
			@include equalAll(40px);
		}

		&.active { border-color: $primary; }
	}

	.monthlist {
		position: relative;
		margin-left: 29px;
		padding-left: 54px;
		border-left: 2px solid $e4;

		@media ( max-width: ( $mobileLandscape - 1px )) {
			padding-left: 34px;
		}

		.item {
			clear: none;
			box-sizing: border-box;
		}

		.dayclearer {
			clear: both;
			display: block;
			margin: 20px 0px;
			border-top: 1px solid $e4;

			&:first-of-type { border: 0px; }
		}
	}

	.backbutton {
		color: $darkGrey;
		font-size: 0.8rem;
		float: right;
		text-decoration: none;

		&:after {
			font-family: 'defaultIcons';
			content: "\e6cf";
		}
	}
}


// Fix for extra small screens
@media ( max-width: ($mobileSmall - 1px) ) {
	.events {

		.monthlist {

			.dayclearer { border: 0px; }

			.item {
				margin: 0px -10px;
			}

			.item .img {
				flex-basis: 80px;
				height: 60px;
				margin-right: 10px;
			}
		}
	}
}
