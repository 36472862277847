// Base layout
.today, .list { margin-bottom: 20px; }
.list { border-bottom: 1px solid $softGrey; }
.list:last-of-type { border-bottom: 0px solid $softGrey; }

@media ( min-width: $mobileLandscape ) {
    &:not(.single) .list {
        width: 50%;
        float: left;
        border: 0px;
    }
}

// JS Overflow for toggle active on mobile and single column widgets
.overflow {
    max-height: 1px;
    overflow: hidden;
    transition: max-height 0.3s ease-in;
}

.active .overflow { max-height: 500px; }

@media ( min-width: $mobileLandscape ) {
    &:not(.single) .overflow { max-height: 500px; }
}
