//
//  Tweaks for specific widget styles
//
&.air {
    .holder {
        margin: 30px 20px; // Equals BigRain overlay padding (sort of)
        width: calc( 100% - 40px );
        height: calc( 100% - 60px );
    }
    
    .titlebar {
        padding-left: 0px;
        margin-bottom: 30px;
    }
}

&.cloud {
    .textbox {
        border-top-right-radius: 0px;
        border-bottom-left-radius: 0px;
    }
}

&.rain {
    // No changes
}

&.bigrain {
    .holder {
        margin: 15px 20px; // Equals BigRain overlay padding (sort of)
        width: calc( 100% - 40px );
        height: calc( 100% - 30px );
    }
    
    .titlebar { padding-left: 0px; }
}

// Reposition navigation when titlebar is show
&.showTitle {

    &.rain, &.cloud {
        .controls { top: -40px; }
        .controls, .opt.active { background-color: rgba( 0, 0, 0, 0.15 ); }
    }
    
    &.bigrain {
        .controls {
            top: -50px;
            background-color: transparent;
            
            .opt {
                width: 30px;
                height: 30px;
                line-height: 30px;
                margin: 2px;
                border: 1px solid $e4;
                border-radius: 3px;
                color: $mediumGrey;
            }
        }
    
        .opt.active {
            background-color: $f5;
            border-color: $orange;
        }
    }
}
