//
//  Message box
//
.forum-full-message {
    $offset: 30px;
    
    padding: $offset;
    
    .content {
        float: none;
        width: 100%;
        margin-bottom: 50px;
    }
    
    .message-head {
        max-width: calc( 100% - 60px );
        margin-bottom: $offset;
        
        h1, h2 { margin-bottom: 0px; }
        .date {
            display: block;
            margin-top: -2px;
            color: $lightGrey;
        }
    }
    
    .quick-edit {
        font-size: 1.4rem;
        color: $yellow;
        border-radius: 3px;
        text-align: center;
        position: absolute;
        right: $offset;
        top: $offset;
        
        @include equalAll( 40px );
        
        &:hover { background-color: $f5; }
    }
    
    // Author info
    .author-information {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        border-top: 1px solid $e4;
        padding-top: 20px;
        
        .profile-icon-info {
            margin: 0px 20px;
            flex: 1 1 auto;
            
            .profile-options a {
                display: inline-block;
                font-size: 0.9rem;
                padding-right: 15px;
                text-decoration: none;
    
                &:hover { text-decoration: underline; }
                .icon { margin-right: 5px; }
            }
        }
    }
}
