//
//  Generic forum elements
//

// Generic box
.forum-box {
    padding: 10px;
    background-color: $white;
    border: 1px solid $softGrey;
    margin-bottom: 10px;
    position: relative;
    
    @media ( min-width: $portrait ) {
        padding: 20px;
        margin-bottom: 20px;
    }
    
    @include clearAfter();
    
    a { color: $forum-blue; }
    .profile-icon { color: $white; }
    
    &.no-content {
        text-align: center;
        
        p {
            font-size: 0.9rem;
            color: $lightGrey;
        }
    
        .main-icon {
            display: block;
            clear: both;
            font-size: 64px;
            color: $lightGrey;
            margin-bottom: 10px;
        }
    }
}

// Header
.forum-between-head {
    display: flex;
    flex-flow: row nowrap;
    margin: 40px 0px 5px 0px;
    
    .title { flex: 1 1 auto; }
    
    h3, h4 {
        font-size: 0.9rem;
        font-weight: bold;
        color: $darkGrey;
    }
    
    .options {
        font-size: 0.8rem;
        
        .label, .opt {
            display: inline-block;
            padding: 4px 7px;
        }
        
        .label { font-weight: bold; }
        
        .opt {
            color: $lightGrey;
            border-radius:3px;
            cursor: pointer;
            
            &.active {
                color: $mediumGrey;
                text-decoration: underline;
            }
            
            &:hover {
                background-color: $e4;
                text-decoration: none;
            }
        }
    }
}

// Forum attachments box
.forum-attachments {
    margin: 30px 0px;
    
    h3, h4 {
        font-size: 0.9rem;
        font-weight: bold;
        padding-bottom: 5px;
        margin-bottom: 0px;
        border-bottom: 1px solid $e4;
    }
    
    .file {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        text-decoration: none;
        color: $mediumGrey;
        border-bottom: 1px solid $e4;
        padding: 5px 0px;
        transition: all 0.2s ease-in;
        
        &:hover { background-color: $f5; }
        & > * { flex: 0 0 auto; }
        .filename { flex: 1 1 auto; }
        
        .icon, .caret {
            flex-basis: 40px;
            line-height: 30px;
            text-align: center;
            margin: 0px;
        }
        
        .fileinfo {
            font-size: 0.9rem;
            color: $lightGrey;
            flex-basis: 120px;
        }
        
        .caret {
            font-size: 18px;
            color: $primary;
        }
    }
}

// Tweak button style
.column.forum, .publication.reaction, .widget.forum {
    .button {
        font-size: 0.9rem;
        font-weight: normal;
        line-height: 30px;
        height: auto;
        min-height: auto;
        padding: 5px 15px;
        transition: all 0.3s ease-in;
        border-radius: 3px;
    
        * { pointer-events: none; }
        
        .icon {
            margin: 0px 0px 0px 20px;
            font-size: 14px;
        }
        
        &:disabled {
            background-color: $f5;
            color: $softGrey;
        }
    }
    
    .button:not(.light) {
        background-color: $primary;
        color: $white;
        
        &:hover { background-color: $forum-blue; }
    }
    
    .button.light, .button.clean { color: $mediumGrey; }
    
    .button.clean {
        background-color: transparent;
        text-decoration: underline;
        border-color: transparent;
        box-shadow: none;
        
        &:hover {
            background-color: $f5;
            color: $lightGrey;
            text-decoration: none;
        }
    }
    
    .button.left {
        float: left;
        
        .icon { margin: 0px 10px 0px 0px; }
    }
    
}

// Fieldgroup (should be in default framework files)
.fieldgroup {
    clear: both;
    box-sizing: border-box;
    position: relative;
    
    @include clearAfter();
    
    & + .fieldgroup { margin-top: 20px; }
    
    label, .question {
        margin-bottom: 0px;
        font-weight: bold;
        line-height: 1.4;
    }
    
    input, textarea, select, .textarea, .select, .answers {
        width: 100%;
    }
    
    textarea {
        width: 100%;
        clear: both;
        margin: 0px;
        box-sizing: border-box;
        height: 270px;
        resize: vertical;
    }
}

// Toolbar tweak
.column.forum, .publication.reaction, .widget.forum {
    .toolbar {
        margin-top: 20px;
        text-align: right;
    }
}
