//
//  Custom styling breadcrumb
//
.breadcrumb {
    .holder { margin: 20px 0px; }
    a, .fa { line-height: 20px; }

    h2 {
		font-size: 1rem;
		display: inline-block;
		margin-right: 10px;
		font-weight: bold;
	}

    a {
        color: $primary;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
            opacity: 0.7;
        }
    }

    .fa {
        color: $lightGrey;
        display: inline-block;
        width: 30px;
        text-align: center;
        font-size: 0.9rem;
    }

    .last {
        color: $mediumGrey;
        font-style: italic;
    }
}
