//
//  Photogallery (fotoboek)
//

// Overview template
.fotoboek {
	position: relative;

	@include clearAfter();

	* {
		box-sizing: border-box;
	}

	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;

	.firstTitle {
		flex: 0 0 100%;
	}


}


// Thumb
.photo {
	flex: 0 0 29%;
	margin: 2%;
	position: relative;

	@media (min-width: $portrait) {
		flex-basis: 22%;
		margin: 1.5%;
	}

	@media (min-width: $landscape) {
		flex-basis: 10.5%;
		margin: 1%;
	}

	// Relative height
	&:after {
		padding-top: 75%;
		content: " ";
		display: block;
	}

	a {
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;

		.largeImage {
			pointer-events: none;
		}
	}

	&:target a .largeImage {
		pointer-events: visible;
	}

	.content {
		max-width: 300px;
		text-align: center;
		margin: 10px auto;
		color: $white;
		display: none;

		@media( min-width: $portrait ){
			max-width: 600px;
		}
	}

	.title { font-weight: bold; }
	.description { font-size: 0.8rem; }


}

// Overlay and close button
.fotoboek .close,
.photo:target a .large {
	position: fixed;
	width: 100vw;
	height: 100vh;
	left: 0px;
	top: 0px;
	pointer-events: none;
}

.fotoboek {

	// Close button
	.close {
		z-index: 21;
		text-decoration: none;
		color: $white;
		font-size: 3rem;
		line-height: 1;
		background-color: rgba(0, 0, 0, 0.8);
		text-align: right;

		span { display: none; }

		.closeIcon {
			position: absolute;
			right: 30px;
			top: 10px;
			text-shadow: 3px 3px rgba(0, 0, 0, 0.3);
			display: block;
		}
	}

	// Next & previous buttons in overlay
	.next, .previous {
		display: none;
		position: fixed;
		z-index: 23;
		color: $white;
		top: 20vh;
		cursor: pointer;
		border-radius: 5px;
		background: none;

		&:before, &:after {
			margin: 0px;
			font-size: 3rem;
			text-shadow: 3px 3px rgba(0, 0, 0, 0.3);
		}

		.label { display: none; }
	}

	.next { right: 4%; }
	.previous { left: 4%; }

	.next, .previous, .close .closeIcon {
		&:hover { color: $e4; }
	}

	@media( min-width: $portrait ){
		.next, .previous { top: 25vh; }
		.next:after { margin-left: 5px; }
		.previous:before { margin-right: 5px; }
	}
}

// Pop-out selected (zoom photo)
.photo:target {
	a .large {
		z-index: 22;
		background-image: none;
		text-align: center;

		img {
			display: block;
			margin: auto;
			border: 5px solid $white;
			max-width: 70vw;
			max-height: 70vh;
			margin-top: 5vh;
			pointer-events: auto;

			@media (min-width: $portrait) {
				border-width: 15px;
				margin-top: 10vh;
				max-width: 80vw;
				max-height: 80vh;
			}
		}

	}

	& ~ .close { pointer-events: auto; }

	.content {
		display: block;
	}
}

// Animatie stuff
.fotoboek .close, .photo .large {
	opacity: 0;
	transition: opacity .5s ease-in-out;
	pointer-events: none;
}

.photo:target {
	& ~ .close, a .large {
		opacity: 1;
		pointer-event: visible;
	}

	& ~ .next,
	& ~ .previous { display: block; }
}

// Bugfix for Safari
body { -webkit-animation: bugfix infinite 1s; }
@-webkit-keyframes bugfix { from { padding: 0; } to { padding: 0; } }
