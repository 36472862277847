//
//  Widget header
//  - new CSS layout and style, based on flex-box
//  - to replace old widget titlebar
//
.widget-header {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    padding: 0px;
    
    .title {
        flex: 1 1 auto;
        padding: 5px 15px;
    }
    
    .options {
        flex: 0 0 auto;
        background-color: rgba( 255, 255, 255, 0.1 );
        padding: 0px;
        
        a, .opt, span {
            display: inline-block;
            min-width: 40px;
            text-align: center;
            padding: 5px 0px;
            box-sizing: border-box;
            text-decoration: none;

        }

        a, .opt {
            &:hover {
                background-color: rgba( 255, 255, 255, 0.2 );
            }
        }

        .label { display: none }
    }
}


&.air {
    .options .opt { font-size: 1.2rem; }
}
