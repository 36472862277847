//
//	Content filter tabs
//
$breakpoint: $mobileLandscape;
$border-color: $e4;

.search-filter-toolbar,
.content-type-tabs ul {
	display: flex;
	flex-flow: row wrap;
	align-items: center;

	@media ( max-width: ( $breakpoint - 1px )){
		justify-content: space-between;
	}
}

.main-tabs, .more-holder {
	li {
		flex: 0 0 auto;
		list-style: none;

		@media (min-width: $breakpoint) {
			display: block;
			opacity: 0;

			&.visible {
				display: block;
				opacity: 1;
			}

			&:not(.visible) {
				pointer-events: none;
				visibility: hidden;
			}
		}
	}

	a {
		color: inherit;
		display: block;
		padding: 7px 12px;
		line-height: 20px;
		text-decoration: none;
		border: 1px solid transparent;
		background-color: $white;
		transition: all 0.15s linear;

		&.active {
			font-weight: bold;
			color: $secondary;
		}

		&:hover { background-color: $f5; }

		&:focus {
			color: $white;
			background-color: $hover-blue;
		}
	}
}

.search-filter-toolbar {
	border-bottom: 1px solid $border-color;
	flex-wrap: nowrap;
	margin-bottom: -1px;
}

.content-type-tabs {
	position: relative;
	flex: 1 1 0px;

	@media ( max-width: ( $breakpoint - 1px )){
		display: none;
	}

	.main-tabs {
		justify-content: flex-start;
		overflow: hidden;
		max-height: 36px;

		a.active { border-color: $border-color $border-color $white $border-color; }
		.more-holder { display: block; }
	}
}

.more-holder {
	flex: 0 0 auto;

	a.active { border-color: transparent; }

	.fold-out {
		max-height: 800px;
		width: 160px;
	}

	@media ( min-width: $breakpoint ) {
		li:not(.visible) {
			display: none;
		}
	}
	&.inactive {
		display: block;

		@media ( min-width: $breakpoint ) {
			visibility: hidden;
			pointer-events: none;
		}
	}
}

.more-button {
	@media ( max-width: ( $breakpoint - 1px ) ) {
		.more-label { display: none; }
	}

	@media ( min-width: $breakpoint ) {
		.mobile-label { display: none; }
	}
}

.content-type-hidden { display: none; }
