/* Set standard fonts */

/* External fonts */
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,300italic,400,400italic,600,600italic,700,700italic);

/* Local fonts */
@font-face { font-family: 'ProximaNova'; src: url('../fonts/ProximaNova.otf') format('opentype'); }

@font-face { font-family: 'roboto-light'; src: url('../fonts/roboto/Roboto-Light.otf') format('opentype'); }
@font-face {
	font-family: 'roboto-light';
	src:url('../fonts/roboto/Roboto-Light.eot?nkd142');
	src:url('../fonts/roboto/Roboto-Light.eot?#iefixnkd142') format('embedded-opentype'),
	url('../fonts/roboto/Roboto-Light.woff?nkd142') format('woff'),
	url('../fonts/roboto/Roboto-Light.ttf?nkd142') format('truetype'),
	url('../fonts/roboto/Roboto-Light.svg?nkd142#roboto') format('svg');
}

@font-face { font-family: 'defaultIcons'; src: url('../fonts/defaultIcons.otf') format('opentype'); }
@font-face {
	font-family: 'defaultIcons';
	src:url('../fonts/defaultIcons.eot?nkd142');
	src:url('../fonts/defaultIcons.eot?#iefixnkd142') format('embedded-opentype'),
	url('../fonts/defaultIcons.woff?nkd142') format('woff'),
	url('../fonts/defaultIcons.ttf?nkd142') format('truetype'),
	url('../fonts/defaultIcons.svg?nkd142#defaultIcons') format('svg');
}

//
//   Typography
//
$font-awesome       : 'Font Awesome 5 Free', 'FontAwesome', sans-serif;
$open-sans          : 'Open Sans', sans-serif;
$font-family-base   : $open-sans;
