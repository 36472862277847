// Fancy header
.titlebar {
    text-align: center;
}

// Subheaders
h4 {
    font-size: 1rem;
    font-weight: bold;
    position: relative;
    padding-right: 30px;
    line-height: 30px;
    cursor: pointer;
    
    .toggle-icon {
        position: absolute;
        right: 0px;
        top: 0px;
        border-radius: 3px;
        text-align: center;
        margin: 0px;
        transition: all 0.3s ease-in;
        transform-origin: center center;
        pointer-events: none;
        
        @include equalAll( 30px );
    }
}

@media ( min-width: $mobileLandscape ) {
    &:not(.single) .toggle-icon {
        display: none;
    }
}

.today h4 { padding: 15px; }
.active h4 .toggle-icon { transform: rotate(90deg); }
