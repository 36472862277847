//
//  Navigation
//
.nav {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 10;
    
    .controls {
        position: absolute;
        top: 0px;
        right: 0px;
        text-align: right;
        background-color: rgba( 0, 0, 0, 0.65 );
        color: $white;
        font-size: 0rem;
        pointer-events: auto;
        
        .steps {
            display: inline-block;
            color: inherit;
        }
    
        .opt {
            display: inline-block;
            width: 40px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            font-size: 0.9rem;
            cursor: pointer;
            color: inherit;
            text-decoration: none;
            
            &.active { background-color: rgba( 255, 255, 255, 0.15 ); }
            &:hover { background-color: rgba( 255, 255, 255, 0.07 ); }
        }
        
        .fa { margin: 0px; }
        .pause span { pointer-events: none; }
    }
}

// Toggle play/pause buttons
& {
    .pause .fa-play { display: none; }
}

&[data-state="paused"] {
    .pause {
        .fa-play { display: inline-block; }
        .fa-pause { display: none; }
    }
}
