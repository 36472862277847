#page.zoeken #wrapper {
    width: 100%;
    max-width: 100%;
    background: $white;
    min-height: 400px;
    margin: 0px;
    overflow: hidden;

    .skip {
        border-radius: 3px;

        &:not(:focus) { box-shadow: none; }
    }

    * { box-sizing: border-box; }
    .form { margin-top: 50px; }
    .searchinput { text-indent: 0px; }

    // FIX FA v4 vs v5
    .fas, .gosMore:after {
        font-family: $font-awesome;
        font-weight: bold;
    }

    // Fix lack over hover-box CSS
    .hover-menu {
        .fold-out {
            position: absolute;
            box-shadow: 0px 0px 5px rgba( 0, 0, 0, 0.15 );
            padding: 10px;
            opacity: 0;
            pointer-events: none;
            transition: all 0.15s linear;
            max-height: 0px;
            z-index: 1;
            background-color: $white;
        }

        &.active {
            .fold-out {
                opacity: 1;
                pointer-events: visible;
                max-height: 100vh;
            }
        }
    }

    // Fix CSS buttons 1px offset
    .input .button { top: 1px; }

    // Fix clean-button
    .clean-button {
        background-color: transparent;
        color: $darkGrey;
    }
}

