/* ############ */
/* #   Menu   # */
/* ############ */

/* Generic styling */
.menu
{
	width: 100%;
	position: relative;
	margin-bottom: 1%;
	min-height: 40px;
	margin-bottom: 10px;
	clear: both;
}

.menu ul { list-style: none; padding: 0px; margin: 0px; }

.menu ul a, .menu ul a:link, .menu > div
{
	display: block;
	padding: 0px 10px;
	margin: 0px 10px;
	text-decoration: none;
	font-size: 1.1rem;
	padding-bottom: 3px;
}

.menu ul a, .menu ul a:link { line-height: 3; }
.menu li a { border-top: 1px dotted rgba(0,0,0,0.15); }
.menu.dark li a { border-top: 1px dotted rgba(255, 255, 255, 0.5); }
.menu li:first-child a{ border-top: 0px; }

/* Make it fancy for mobile*/
.menu ul, .menu > div { display: none; }
.menu.opened ul, .menu.opened > div { display: block; }

.menu h2, .menu h3 { padding-left: 10px; line-height: 2.7rem;  }
.menu h2 { font-size: 1.6rem; }
.menu h3 { font-size: 1.3rem; }

.menu:after { content: " "; clear: both; display: block; }

.menu .toggle { line-height: 40px; cursor: pointer; display: block; padding: 0 10px; right: 0; top: 0px; }
.menu .toggle:after {  font-size: 1.2rem; top: 1px; }

.menu .toggle.opened { line-height: 48px; }

.menu li:not(.home) a.active { background-color: rgba( 255,255,255,0.3); }
.menu a:hover { background-color: rgba( 255,255,255,0.15); }

@media (min-width: 400px) {

}

@media (min-width: 700px) {
	.menu .toggle { display: none; }
	.menu h2, .menu h3 { display: none; }

	.menu ul a, .menu ul a:link
	{
		display: block;
		padding: 0px 10px;
		margin: 0px;
		font-size: 1rem;
	}

	.menu li a, .menu.dark li a { border-top: 0px dotted #000; }
	.menu ul,  .menu > div { display: block; }

	.menu .button, .menu .quicksearch { width: auto; float: right; margin: 0.7% 0.7% 0% 0; }

	.menu ul a { float: left; }
	.menu > div { margin-right: 0px; padding-right: 0px; }

	.menu .quicksearch { max-width: 160px; }
}

@media (min-width: 1000px) {
	.menu ul a, .menu ul a:link
	{
		display: block;
		padding: 0px 20px;
		font-size: 1.1rem;
	}
}