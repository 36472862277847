/* ############## */
/* #   Footer   # */
/* ############## */
footer
{
padding: 5px;
margin-top: 0px;
line-height: 30px;
height: 40px;
position: relative;
bottom: 0px;
left: 0px;
width: 100%;
box-sizing :border-box;
}

footer .copy, footer .copy a { font-size: 0.8rem; }
footer .powered { display: block; }
footer a.top { float: right; text-decoration: none; }

@media (min-width: 600px) {
	footer .powered { display: inline; }
}

@media print {
	/* Footer */
	html body footer { background: #FFFFFF; border-top: 1px solid #CCCCCC; }
	footer a.top { display: none; }
}