/* Generic button */
.button, input[type=submit], #formulier button[type=button]
{
	font-size: 1rem;
	padding: 10px;
	padding-right: 15px;
	box-sizing: border-box;
	position: relative;
	background: #CCC;
	display: inline-block;
	text-decoration: none;
	cursor: pointer;
	color: #FFFFFF;
	line-height: 30px;
    border:none;
}

.button span { font-size: inherit; }
.button .fa { margin: 0px 5px 0px 0px; }

.buttons { clear: both; padding-top: 10px; }
.button.smaller, .button.small { padding: 0px 10px; font-size: 0.9rem; }


.button.next, .button.previous {  }
.button.next:after, .button.previous:before { font-family: "Font Awesome 5 Free"; display: inline-block; font-weight: 900; }

.button.next:after { content: "\f054"; margin-left: 5px; }
.button.previous:before { content: "\f053"; margin-right: 5px; }

@media print {
	.button { border: 1px solid #CCC; background-color: transparent!important; }
	.button:after { clear: both; display: block; line-height: 1; }
}

/* Edit button */
.button.edit
{
	float: right;
	padding: 0px 5px;
	margin: 1px;
	position: relative;
	text-decoration: none;
	background-color: #FFA800;
	border-radius: 5px;
	line-height: 1.6;
	font-size: 1rem;
}

.button.edit:after { content: "\e606"; color: #FFFFFF; font-family: 'defaultIcons'; }
.button.edit:hover { background: #3C853A; }

.editTitle { text-indent: -9999px; }
.editTitle .button.edit { text-indent: 0px; }

.title .button.edit { position: absolute; top: 0px; right: 0px; }
.widget .button.edit { position: absolute; right: 2%; top: 2%; }

// Light style buttons
.button.light {
	box-sizing: border-box;
	position: relative;
	line-height: 30px;
	height: auto;
	min-height: auto;
	padding: 5px 15px;
	border-radius: 3px;
	background-color: $white;
	border: 1px solid $e4;
	color: $mediumGrey;
	transition: all 0.2s ease-in;
	
	&:hover {
		border-color: $softGrey;
		box-shadow: 0px 0px 5px rgba( 0, 0, 0, 0.1 );
	}
}
