//
//  Widget footer
//  - new CSS layout and style, based on flex-box
//  - to replace old widget titlebar
//
.widget-footer {
    position: relative;
    
    .left { float: left; }
}
