#page {
    min-height: 100vh;
    width: 100%;
    min-width: 320px;
}

#page.acceptatie { background-image: url("../images/devenvironment.png"); }
#wrapper {
    min-height: auto;
    clear: both;
}

#page:after, #wrapper:after {
	content: " ";
	display: block;
	clear: both;
}

// Flexible height? Zet footer onder
#page {
    display: flex;
    flex-flow: column nowrap;
}

header, footer { flex: 0 0 auto; }
#wrapper {
    flex: 1 1 auto;
    min-height: 400px;
}

@media print {
    #page { display: block; }
}


// Container ( from framework components/containers_v1.scss )
.container {
    max-width: 1200px;
    width: calc(100% - 20px);
    margin: 0px 10px;
    min-width: 280px;

    @media (min-width: 360px){
        margin: 0px 20px;
        width: calc( 100% - 40px );
    }

    @media (min-width: 480px){
        margin: 0px 30px;
        width: calc( 100% - 60px );
    }

    @media (min-width: $portrait){
        margin: 0px 50px;
        width: calc( 100% - 100px );
    }

    @media (min-width: $desktop){
        margin: 0px 100px;
        width: calc( 100% - 200px );
    }

    @media (min-width: 1240px){
        margin: 0px auto;
    }
    
    &:after {
        content: " ";
        clear: both;
        display: block;
    }
}

.container.fullWidth {
    max-width: 100%;
    width: 100%;
    margin: 0px;
}


#overlays { z-index: 50; }
#panels{ z-index: 49; }
