//
//  Related and documents
//  - variant of the related component
//  - shows two lists based on the related content ( instead of one single )
//  - one list with related content, the other with downloads (files and images)
//  - shows a FontAwesome icon instead of arrow
//
.relatedAndDownloads {
    
    h3 {
        font-size: 1.1rem;
        font-weight: bold;
        border-bottom: 1px solid $e4;
        padding-bottom: 5px;
        margin-bottom: 10px;
        
        .fa, .icn {
            display: inline-block;
            width: 24px;
            text-align: center;
        }
    }
    
    ul.list li {
        line-height: 1.2;
        padding: 0px;
        margin: 10px 0px;
        
        .fa, .icn {
            position: absolute;
            left: 5px;
            top: 5px;
            width: 16px;
            text-align: center;
            line-height: 1.1;
        }
        
        &:before { display: none; }
        .small { display: none; }
        
        a {
            display: block;
            padding: 5px 5px 5px 30px;
            
            &:hover {
                text-decoration: underline;
                background-color: $f5;
            }
        }
    }
    
    .list { margin-bottom: 40px; }
    
    &.hasDocReader .file a {
        width: calc(100% - 40px);
        
        @media ( max-width: ( $portrait - 1px ) ) {
            .label { display: none; }
        }
        
        @media ( min-width: $portrait ) {
            width: calc(100% - 130px);
        }
    
        @media ( max-width: ( $portrait - 1px ) ) {
            width: calc(100% - 60px);
        }
    }
}

// Side by side when below content and on tablet (or larger)
.relatedAndDownloads {
    width: 100%;
    clear: both;
    float: none;
    
    @media(min-width: $portrait) {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    
        .related-items, .related-documents {
            flex: 0 0 calc(50% - 20px);
        }
    }
}
