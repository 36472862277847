//
//  Edit profile
//  - specific styling and tweaks for the profile edit page / functionalitity
//
.editemployee {
    
    h2, h3 { display: none; }
    
    .fieldgroup {
        margin: 20px 0px;
    
        label:not(.button) {
            line-height: 1;
            padding-top: 7px;
        }
    }
    
    .cms-insert {
        margin: 20px 0px;
        
        @media ( min-width: $portrait ) {
            width: 33%;
            float: left;
        }
        
        label { display: none; }
        .answers label { display: block; }
    }
    
    .answers {
        .profile-icon {
            float: left;
            margin-right: 20px;
            width: 70px;
            height: 70px;
        }
    
        .image-input-holder {
            width: 300px;
            float: left;
            padding-top: 20px;
        }
    }

    .schedule {
        & > .fieldgroup {
            margin: 20px 0px;
            
            @media ( min-width: $portrait ) {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                justify-content: center;
            }
    
            @media ( min-width: $landscape ) and ( max-width: ( $desktop - 1px ) ) {
                flex-flow: row wrap;
            }
            
            h5 {
                font-size: 1rem;
                font-weight: normal;
    
                @media ( min-width: $portrait ) {
                    flex: 0 0 130px;
                    margin: 0px;
                }
                
                @media ( min-width: $landscape ) and ( max-width: ( $desktop - 1px ) ) {
                    flex: 0 0 100%;
                }
            }
            
            .fieldgroup {
                margin: 5px 0px;
                
                label { width: 60px; }
                input[type='text']{ width: calc( 100% - 60px ); }
                
                @media ( min-width: $portrait ) {
                    flex: 1 1 20%;
                    margin-right: 15px;
                    
                    label { width: 40px; }
                    input[type='text']{ width: calc( 100% - 40px ); }
                    
                    &:last-of-type {
                        flex: 1 1 50%;
                    
                        label { width: 60px; }
                        input[type='text']{ width: calc( 100% - 60px ); }
                    }
                }
            }
        }
    }
    
    .toolbar {
        clear: both;
        margin: 20px 0px;
        text-align: right;
        .fa { margin-right: 5px; }
        
        .edit-save .fa { color: $blue; }
        .edit-cancel .fa { color: $lightGrey; }
    }
    
    .edit-head {
        clear: both;
        
        @include clearAfter();
    }
    
    .edit-title {
        display: block;
        font-size: 1.6rem;
        font-weight: 300;
    
        @media (min-width: $portrait) {
            float: left;
            width: 300px;
            
            & + .toolbar {
                width: calc(100% - 300px);
                clear: none;
                float: right;
                margin-top: 0px;
            }
        }
    }
    
    // Fancy datepicker input
    .date-selector {
        & > .date-selector { display: none; }
        
        .input-box-holder {
            display: flex;
            flex-flow: row nowrap;
    
            .input-box {
                //flex: 0 0 120px;
                margin-right: 10px;
                
                input[type='number'] {
                    -webkit-appearance: textfield;
                    -moz-appearance:    textfield;
                    appearance:         textfield;
                }
                
                select, input {
                    border: 1px solid $softGrey;
                    padding: 4px;
                    border-radius: 3px;
                }
                
                input { padding: 6px; }
            }
        }
    }
}
