//
//  Fieldgroup
//  - defines styling of container with label and value ( or input )
//
.fieldgroup {
    
    // Variables
    $labelWidth: 120px;
    
    // Base box-modal
    font-size: 0.9rem;
    line-height: 1.8;
    
    @include clearAfter();
    
    // Reset
    &, * {
        position: relative;
        box-sizing: border-box;
    }
    
    .label, label {
        color: $lightGrey;
        float: left;
        width: $labelWidth;
        padding-right: 10px;
        display: block;
    }
    
    .value, input[type='text'], .answers, textarea, select {
        display: block;
        float: left;
        color: $mediumGrey;
        width: calc( 100% - #{$labelWidth} );
    }
    
    .answers label {
        color: $mediumGrey;
        margin-bottom: 2px;
    }
    
    .italic {
        font-style: italic;
        color: $lightGrey;
        display: inline-block;
        padding-left: 10px;
    }
    
    // Break style
    &.break {
        margin-bottom: 10px;
    
        .label, .value {
            width: 100%;
            float: none;
        }
        
        .label {
            color: $darkGrey;
            font-weight: bold;
            margin-bottom: 10px;
            font-size: 1rem;
        }
    }
    
    
    // Fancy radio buttons and checkboxes
    input[type='checkbox'], input[type='radio'] { display: none; }
    
    input[type='checkbox']+label,
    input[type='radio']+label {
        width: 100%;
        clear: both;
        padding: 5px 0px 5px 30px;
        position: relative;
        
        &:before {
            display: block;
            position: absolute;
            left: 0px;
            top: 0px;
            content: "\f00c";
            font-family: $font-awesome;
            color: $f5;
            text-align: center;
            border-radius: 3px;
            border: 1px solid $e4;
            margin-right: 10px;
            transition: all 0.3s ease-in;
            
            @include equalAll( 22px );
        }
    }
    
    input[type='checkbox']:checked+label:before,
    input[type='radio']:checked+label:before {
        background-color: $green;
        color: $white;
        font-style: normal;
        border-color: $green;
    }
    
    input[type='radio']+label:before {
        border-radius: 100%;
    }
    
    // Input stylinh and disabled
    input[type='text'], input[type='date'], textarea, select {
        border-radius: 3px;
        
        &:disabled, .disabled {
            background-color: $f5;
            color: $lightGrey;
        }
    }
    
    textarea, iframe {
        resize: horizontal;
        min-height: 300px;
    }
    
    
}

.half .fieldgroup {
    $labelWidth: 90px;

    .label { width: $labelWidth; }
    .value { width: calc( 100% - #{$labelWidth} ); }
}
