/* ######################### */
/* #   Submenu & Content   # */
/* ######################### */

#submenu, #submenuBreadcrumb {
    margin: 20px 0px;
    padding: 0px;
}

.related {
    margin: 20px 0px;
    
    h3 { font-size: 1.3rem; }
    
    li {
        background: transparent;
        clear: both;
        
        &.image { min-height: auto; }
    }
}

#system { margin: 20px 0px; position: relative; }

@media screen and (min-width: 700px) {
	.submenuTitle { display: none; }
}

@media print {
	.submenuTitle , #submenu { display: none; }
	#content, #system { margin: 0px; }
}

/* Alternate submenu content  */
/* sidebar kleur wijzigen */
.submenuSiblingPublications h3 {
    padding: 10px 15px;
    background-color: $primary;
    font-size: 1.1rem;
    font-weight: bold;
    color: $white;
}

.submenuSiblingPublications ul+ul > li:first-of-type > a { border-top: 1px dotted rgba(0,0,0,0.2); }

// Overwrite for related below content, not in right column
.publication .related {
    width: 48%;
    float: left;
    padding-top: 50px;
}

.publication .related.terms {
    float: right;
    margin-right: 0px;
}

.publication .related:last-of-type {
    width: 100%;
    clear: both;
    float: none;
}

.hasDocReader .file {
    display: flex;
    flex-flow: row nowrap;

    a {
        flex: 1 1 auto;
    }
    
    .docReaderButton {
        flex: 0 0 110px;
    }
}

@media print {
    .related { display: none; }
}
