//
//  News widgets
//  - extends basic article / item widget(s) ?
//  - extends _listWidget
//  - styling for several news widgets: newsBlockLarge, newsBlock, newsList, newsListLatest, newsListTitles
//

// NewsBlockLarge
.newstiles {
    .item {
        border-bottom: 0px;
        padding-bottom: 0px;

        .more { float: right; }
    }

    .itemcontent  { padding: 10px 15px; }
    .titlebar { margin-bottom: 15px; }
}

// Style specific tweaks
.cloud {
    &.newstiles {
        .imageheader { border-radius: 7px 7px 0px 0px; }
    }
}

.air, .bigrain {
    &.newstiles {
        .titlebar { padding-left: 0px; }
    }
}

.bigrain {
    &.newstiles {
        .overlay { padding: 15px; }
    }
}

// NewsList widgetg (NewsList)

.listwidget.news {
    .titlebar h3 {
        padding-right: 15px;
    }

    &.bigrain .titlebar h3 { padding-right: 50px; }
}


