.hovermenu > ul > li
{
	position: relative;
	display: block;
	float: left;
}
.hovermenu li > div { display: none; }
.hovermenu li:hover > div { display: block; }

.hovermenu li > div
{
	position: absolute;
	background: #FFFFFF;
	min-width: 200px;
	max-width: 300px;
	width: 20vw;
	left: auto;
	top: 46px;
	border-radius: 0px 0px 7px 7px;
	box-shadow: 0px 2px 0px rgba(0,0,0,0.07);
}

.hovermenu li > div a
{
	border-bottom: 1px dotted rgba(0, 0, 0, 0.2);
	display: block;
	float: none;
}
.hovermenu li > div a, .hovermenu li > div a * { color: #303F4C; }

.hovermenu li > div li:hover { background-color: #F7F7F7; }
.air .hovermenu li > div li:last-of-type { border-radius: 0px 0px 5px 5px }
.hovermenu li > div li:hover a { color: #F47721; }
.hovermenu li > div li:last-of-type a { border-bottom: 0px; }

@media (max-width: 700px) {
	.hovermenu li > div { display: none !important; }
	.hovermenu > ul > li { float: none; }
}
