//
//	Quicksearch
//
.quicksearch {
	position: relative;

	fieldset { margin-bottom: 0px; }

	input[type=text] {
		border: 1px solid #d5dee5;
		background: transparent;
		width: calc(100% - 50px);
		text-indent: 15px;
		height: 40px;
		line-height: 35px;
		padding: 0px;
		box-sizing: border-box;

		&:focus { border: 1px solid #D5DEE5; background-color: #EDF7FF; }
	}

	.button {
		position: absolute;
		right: 0px;
		top: 0px;
		width: 50px;
		height: 100%;
		text-align: center;
		font-size: 1.2rem;
		display: block;
		box-shadow: none;
		cursor: pointer;
		margin: 0px;
		z-index: 3;
		line-height:1.9rem;

		.fa, .icn { margin-right: 0px; }
	}
}

// Quicksearch in header, assistent
#assistent .quicksearch .dummybutton { padding: 5px 15px; }
#assistent .quicksearch { padding: 0px; }
#assistent .quicksearch .overlay { padding: 0px; }

// Quicksearch in toggleblock ( disclosures, legislations

.toggleblock .quicksearch {
	.base  { background-color: $white; }
	.button { line-height: 100%; }
}

@media print {
	.quicksearch { display: none; }
}

//
// Quicksearch in widget styling
//

// Air
.widget.air.quicksearch {
	.overlay:not(.bgColorTransparent) {
		padding: 0px;
	}

	input {
		width: 100%;
		padding: 15px;
		height: 60px;
		text-indent: 5px;
	}

	.button {
		top: 10px;
		right: 10px;
		bottom: 10px;
		height: auto;
	}
}

// Cloud
.cloud.quicksearch {
	input {
		border-radius: 7px 0px 0px 7px;
		border-right: 0px;
	}

	.button {
		box-shadow: none;
		border-radius: 0px 7px 7px 0px;
		border-right: 0px;
	}
}

// Bigrain
.bigrain.quicksearch {
	.overlay { padding: 0px; }
}

// Colors
.row.image, .row.bgColorA, .row.bgColorB, .row.bgColorgo, .row.bgColorGO {
    input[type='text'], input[type='text']:focus { border-color: #FFFFFF; }
}
