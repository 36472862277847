/* ###################### */
/* #   Default colors   # */
/* ###################### */

// White
.bgColorWhite, .base.bgColorWhite { background-color: $white; }
.fgColorWhite, .fgColorWhite * { color: $white; }

// Black
.bgColorBlack, .base.bgColorBlack { background-color: $black; }
.fgColorBlack, .fgColorBlack * { color: $black; }

// Grey
.bgColorGrey, .base.bgColorGrey { background-color: $mediumGrey; }
.fgColorGrey, .fgColorGrey * { color: $mediumGrey; }

// Dark Grey
.bgColorDarkGrey, .base.bgColorDarkGrey { background-color: $darkGrey; }
.fgColorDarkGrey, .fgColorDarkGrey * { color: $darkGrey; }

// Light grey
.bgColorLightGrey, .base.bgColorLightGrey { background-color: $f5; }
.fgColorLightGrey, .fgColorLightGrey * { color: $f5; }

// Medium grey
.bgColorMediumGrey, .base.bgColorMediumGrey { background-color: $secondSoftGrey; }
.fgColorMediumGrey, .fgColorMediumGrey * { color: $secondSoftGrey; }

// GO Orange
.bgColorGO, .base.bgColorGO, .bgColorgo, .base.bgColorgo { background-color: $orange; }
.fgColorGO, .fgColorGO *, .fgColorgo, .fgColorgo * { color: $orange; }

// Transparent
.bgColorTransparent, .base.bgColorTransparent { background-color: transparent; }


.bgBefore {
    &:before {
        content: " ";
        display: block;
        position: absolute;
        right: 0px;
        top: 0px;
        left: 0px;
        bottom: 0px;
        background-color: #000000;
        opacity: 0.8;
        z-index: 1;
        pointer-events: none;
    }

    .container, .grid { z-index: 2; }
}

.bgColorWhite.bgBefore:before { background-color: $white; opacity: 0.9; }
.bgColorBlack.bgBefore:before { background-color: $black; }
.bgColorGrey.bgBefore:before { background-color: $mediumGrey; }
.bgColorDarkGrey.bgBefore:before { background-color: $darkGrey; }
.bgColorLightGrey.bgBefore:before { background-color: $f5; }
.bgColorMediumGrey.bgBefore:before { background-color: $secondSoftGrey; }
.bgColorGO.bgBefore:before, .bgColorgo.bgBefore:before { background-color: $orange; }

// Print
@media print {
    .bgBefore:before { display: none; }
}

// High contrast
#page.highcontrast .bgBefore:before {
    opacity: 0.3;
}
