/* Overwrite all for high-contrast */
#page.highcontrast #navigation,
#page.highcontrast #navigation #menu
{
background: #000000;
}

#page.highcontrast #navigation #menu a, #page.highcontrast #navigation #menu span { color: #FFFFFF!important; }
#page.highcontrast .row { background: #FFFFFF!important; }
#page.highcontrast .row+.row { border-top: 1px solid #000000; }
#page.highcontrast .widget.base, #page.highcontrast .widget .base { border: 1px solid #000000; box-shadow: none; background-color: transparent; }
#page.highcontrast .button { background-color: #000000; color: #FFFFFF; }

#page.highcontrast h1, #page.highcontrast h2, #page.highcontrast h3 { color: #000000; } 
#page.highcontrast a { color: #000000; }
#page.highcontrast {
    .skip, .main-tabs a:focus, .more-button {
        color: $white;
    }
}


#page.highcontrast .tabs a,
#page.highcontrast .taboverview a.tabbarItem 
{
border: 1px solid #000000;
color: #000000;
background: #FFFFFF;
}

#page.highcontrast .button {
    background-color: #000000;
    color: #FFFFFF;

    .fa, .icn { color: inherit; }
}

//widget-tile outline color
#page.highcontrast {
    .image-focus, .dark-focus {
        a, button, input.button {
            &:focus {
                outline-color: $black!important;
            }
        }
    }
}

//search-pointer contrast color
#page.highcontrast .results .title .search-pointer {
    color: $black;
}

//search results border color
#page.highcontrast #searchresults strong:not(.inheritStyle) {
    border: 1px solid #1D9CD3;
}

#page.highcontrast .tabs a
{
border-bottom: 0px;
}

#page.highcontrast .tabs a.active,
#page.highcontrast .tabs li.active a,
#page.highcontrast .subtabs li.active span,
#page.highcontrast .taboverview a.tabbarItem.active
{ background-color: #000000; color: #FFFFFF; }

#page.highcontrast .subtabs a, #page.highcontrast .subtabs li > span { border: 1px solid #000000; }
#page.highcontrast .hovermenu li > div { background: #222222; }
#page.highcontrast .hovermenu li > div li:hover { background-color: #444444; }

#page.highcontrast .taboverview .tabcontent.active .content, #page.highcontrast .taboverview .tabcontent:target .content
{
background-color: #FFFFFF;
border-color: #000000;
}

#page.highcontrast #breadcrumb a, #page.highcontrast #breadcrumb span { color: #000000; }
#page.highcontrast footer { background-color: #FFFFFF; color: #000000; border-top: 1px solid #000000; }
#page.highcontrast .longcalendar .days li.today { background-color: #000000; }
#page.highcontrast .longcalendar .days li.today a { color: #FFFFFF; }
