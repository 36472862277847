/* Set font */
@font-face 
{
font-family: 'TiO';
font-weight: normal;
font-style: normal;
src:  url('../fonts/TiO/TiO.eot?v0.1');
src:  url('../fonts/TiO/TiO.eot?v0.1#iefix') format('embedded-opentype'),
url('../fonts/TiO/TiO.ttf?v0.1') format('truetype'),
url('../fonts/TiO/TiO.woff?v0.1') format('woff'),
url('../fonts/TiO/TiO.svg?v0.1#TiO') format('svg');
}

.tio
{
font-family: 'TiO' !important;
speak: none;
font-style: normal;
font-weight: normal;
font-variant: normal;
text-transform: none;
line-height: 1;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
display: inline-block;
}

// Default icon ( euro symbol )
.tio:before { content: "\e90b"; }

// Defined icons 
.tio-buitenland:before									{ content: "\e900"; }
.tio-buitenland_werken_wonen_en_reizen:before			{ content: "\e900"; }

.tio-consumentenzaken:before							{ content: "\e901"; }

.tio-familie:before										{ content: "\e902"; }
.tio-familie_jeugd_en_gezin:before						{ content: "\e902"; }

.tio-immigratie:before									{ content: "\e903"; }
.tio-immigratie_inburgering_en_integratie:before		{ content: "\e903"; }

.tio-jezelf:before										{ content: "\e904"; }
.tio-voor_jezelf_beginnen:before						{ content: "\e904"; }
.tio-ondernemen:before									{ content: "\e904"; }

.tio-media:before										{ content: "\e905"; }
.tio-cultuur_sport_en_vrije_tijd:before					{ content: "\e905"; }

.tio-natuur:before										{ content: "\e906"; }
.tio-natuur_en_milieu:before							{ content: "\e906"; }

.tio-onderwijs:before									{ content: "\e907"; }

.tio-overheid:before									{ content: "\e908"; }
.tio-overheid_en_democratie:before						{ content: "\e908"; }

.tio-recht:before										{ content: "\e909"; }
.tio-rechtspraak_en_veiligheid:before					{ content: "\e909"; }

.tio-register:before									{ content: "\e90a"; }
.tio-uittreksels_en_registers:before					{ content: "\e90a"; }

.tio-uitkeringen:before									{ content: "\e90b"; }
.tio-belastingen_uitkeringen_en_toeslagen:before		{ content: "\e90b"; }

.tio-verkeer:before										{ content: "\e90c"; }
.tio-verkeer_voertuigen_en_wegen:before					{ content: "\e90c"; }

.tio-werk:before										{ content: "\e90d"; }
.tio-werk_en_loopbaan:before							{ content: "\e90d"; }

.tio-wonen:before										{ content: "\e90e"; }
.tio-wonen_en_leefomgeving:before						{ content: "\e90e"; }

.tio-zorg:before										{ content: "\e90f"; }
.tio-zorg_en_gezondheid:before							{ content: "\e90f"; }




