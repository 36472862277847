/* ############################################### */
/* #   Submenu variant breadcrumb 				 # */
/* #   Shows the submenu as the breadcrumb pad   # */
/* ############################################### */

.menuList.submenuBreadcrumb li:not(:first-of-type) a
{
font-size: 0.9rem;
padding-left: 10px;
border-bottom: 0px;
}

.menuList.submenuBreadcrumb li:not(:first-of-type) a:before
{
content: " ";
display: block;
float: left;
border: 5px solid #CCC;
position: relative;
margin-top: 3px;
margin-right: 5px;
border-color: transparent transparent transparent rgba(0,0,0,0.2);
}
