/* Base Google Maps Component */
#googlemap
{
height: 500px;
width: 100%;
box-sizing: border-box;
background: #FFFFFF;
margin-bottom: 20px;
}

@media (max-width: 600px) {
	#googlemap { height: 300px; }
}

#googlemap.waiting .static, #googlemap.active .static { display: none; }
#googlemap.waiting:after
{
content: "Map word geladen";
position: absolute;
left: 50%;
top: 47%;
margin-left: -100px;
width: 200px;
text-align: center;
}

#googlemap.active { border: 5px solid #FFF; }
#googlemap .static { border: 5px solid #FFF; display: block; margin: auto; width: 100%; }
#goData, .goData { display: none!important; }
