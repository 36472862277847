//
//  Tabbar specific for employees
//
.profile-tabs {
    display: flex;
    flex-flow: row nowrap;
    
    .profile-tab {
        flex: 1 1 auto;
        background-color: $f5;
        padding: 5px 30px;
        margin-bottom: -1px;
        text-align: center;
        line-height: 30px;
        text-decoration: none;
        transition: all 0.2s ease-in;
        text-shadow: 0px 0px 0px #FFFFFF;
        text-transform: uppercase;
        color: $lightGrey;
        cursor: pointer;
        
        &.active {
            color: $primary;
            background-color: $white;
            box-shadow: 2px -2px 2px rgba( 0, 0, 0, 0.05 );
            z-index: 5;
        }
        
        &:not(.active):hover {
            background-color: $e4;
            color: $mediumGrey;
        }
        
        &:not(:first-child) { margin-left: -1px; }
    }
    
    // Hide when there's only one tab
    .profile-tab:first-child:last-child {
        display: none;
    }
}
