//
//  Employee publication
//  - shows a employee profile page
//
.publication.employee {
    
    // Generic parts
    @import 'base';
    @import 'fieldgroup';
    @import 'tabs';
    
    // Specific parts
    @import 'profileCard';
    @import 'social';
    
    //
    // Style tweaks
    //
    
    // Larger intro text
    .intro { font-size: 1rem; }
    
    // Deny padding-right of contact
    .profile .block .contact { padding-right: 0px; }
    
    // Hide labels at address
    @media( min-width: $portrait ) {
        .profile .block .half.address {
            max-width: 200px;
            margin-left: 25px;
        
            .fieldgroup, .label, .value { display: inline-block; }
        
            .fieldgroup {
                .label { display: none; }
                .value { width: auto; }
            }
        
            .post, .place {
                display: block;
                clear: both;
            }
        }
    }
    
    // Subvalue, add comma
    .subvalue:not(:last-of-type):after {
        content: ",";
    }
    
    // Edit employee, added at end to overwrite previous styling
    @import "edit";
    
}
