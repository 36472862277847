.modalDialog {
    position: fixed!important;
    font-family: Arial, Helvetica, sans-serif;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255,255,255, 0.8);
    z-index: -99999;
    opacity:0;
    -webkit-transition: opacity 400ms ease-in;
    -moz-transition: opacity 400ms ease-in;
    transition: opacity 400ms ease-in;
    pointer-events: none;
}
.modalDialog:target {
    opacity:1;
    pointer-events: auto;
	 z-index: 99999;
}
.modalDialog div.holder {
    width: 50vw;
    position: relative;
    margin: 10% auto;
    background: #fff;
    box-shadow: 0px 0px 15px rgba( 0,0,0,0.15);
}
.modalDialog .modalMessage {
    padding: 5px 20px 13px 20px;
}

.modalDialog .buttons {
    background: #f5f5f5 none repeat scroll 0 0;
    clear: both;
    color: #666;
    cursor: pointer;
    display: block;
    font-size: 13pt;
    line-height: 50px;
    text-align: center;
    text-decoration: none;
    padding:10px 15px;
}
.modalDialog a.button:hover {color:#FFFFFF;}
.modalDialog .button {
    padding: 10px 25px;
    margin-top: 3.5%;
}