//
//  Search form
//

.searchform {
    
    // Reset
    input:not(.button), label:not(.button) {
        margin: 0px;
        padding: 0px;
    }
    
    fieldset {
        margin: 25px 0px;
        
        &:last-of-type { margin-bottom: 0px; }
        
    }
    
    // Column layout
    .filter-column { margin-bottom: 20px; }
    
    @media( min-width: $mobileLandscape ) {
        .filter-column-holder {
            display: flex;
            flex-flow: row wrap;
        }
    
        .filter-column { flex: 1 1 33%; }
    }
    
    // Base fieldgroup
    .fieldgroup {
        position: relative;
        box-sizing: border-box;
        margin: 0px 0px 5px 0px;
    }
    
    .quicksearch {
        .search-value {
            width: 100%;
            margin: 0px;
            padding-right: 40px;
            border-radius: 3px;
        }
    
        .search-icon, .search-fake-button {
            position: absolute;
            right: 0px;
            bottom: 0px;
            text-align: center;
    
            @include equalAll( 40px );
        }
    
        .search-icon {
            color: $softGrey;
            pointer-events: none;
        }
    }
    
    // Fancy checkboxes
    input[type='checkbox'] { display: none; }

    input[type='checkbox']+label {
        width: 100%;
        clear: both;
        padding: 1px 0px 1px 30px;
        position: relative;
        
        &:before {
            display: block;
            position: absolute;
            left: 0px;
            top: 0px;
            content: "\f00c";
            font-family: $font-awesome;
            color: $f5;
            text-align: center;
            border-radius: 3px;
            border: 1px solid $e4;
            margin-right: 10px;
            transition: all 0.3s ease-in;
            
            @include equalAll( 22px );
        }
    }
    
    input[type='checkbox']:checked+label:before {
        background-color: $green;
        color: $white;
        font-style: normal;
        border-color: $green;
    }
    
    .toolbar {
        text-align: right;
        
        .reset-filters {
            display: inline-block;
            padding: 0px 15px;
            margin: 0px 15px;
            cursor: pointer;
            font-size: 0.9rem;
            color: $lightGrey;
            transition: all 0.2s linear;
    
            &:hover { color: $mediumGrey; }
            .fa { margin-left: 5px; }
        }
    }
    
    // Specific tweaks
    .filters { margin: 0px 5px; }
    .filters h4 { margin-bottom: 10px; }
}
