//
//  GovMetrics
//  - styling for the govMetrics plugin
//

// Custom styling


// Styling for the react plugin (from GovMetrics)
@media (max-width: 600px) {
    .gm_sidebar.gm_sidebar_rh { display: none !important; }
}

@media (min-width: 601px) {
    .gm_sidebar.gm_sidebar_cnr { display: none !important; }
    .gm_sidebar.gm_sidebar_rh { display: initial; }
}
