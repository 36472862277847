/* Page navigation */
.pagenavigation {
	clear: both;
	text-align: center;
	position: relative;
	margin-top: 30px;

	.pages-holder {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
	}

	.no-button, .button { flex: 0 0 120px; }

	.button {
		&:hover { text-decoration: none; }
	}

	.total {
		clear: both;
		font-size: 0.9rem;
		color: $mediumGrey;
		display: block;
		padding-top: 15px;
		text-align: center;
	}

	.previous, .next {
		display: flex;
		flex-flow: row nowrap;

		span { flex: 1 1 auto; }
	}

	.previous:before, .next:after {
		flex: 0 0 16px;
		font-family: $font-awesome;
		font-weight: bold;
		color: $primary;
		display: inline-block;
		width: 16px;
		text-align: center;
	}

	.previous:before {
		content: "\f104";
		margin-right: 5px;
	}

	.next:after {
		content: "\f105";
		margin-left: 5px;
	}

	@media ( max-width: ( $mobileLandscape - 1px )) {
		.no-button, .button {
			flex-basis: 24px;
			display: block;
			padding: 0px;
			text-align: center;

			&:before, &:after { margin: 0px; }
			 span { display: none; }
		}
	}
}

.pages {
	text-align: center;
	margin: 0px 30px;

	a {
		text-decoration: none;

		&:hover {
			background-color: $hover-blue;
			color: $white;
		}
	}

	a, span.current {
		display: inline-block;
		width: 30px;
		height: 30px;
		line-height: 30px;
		margin: 0px;
		padding: 0px;
		color: $mediumGrey;
		border: 0px;
		background: transparent;
	}

	span.current {
		background-color: $secondary;
		color: $white;
		font-weight: bold;
	}
}
