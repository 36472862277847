//
//  Widget tabcontent
//  - shows articles
//  - Note! Does not contain al styling only the new styling and some fixes, old styling can be found in widgets.css
//
.widget.tabcontent {

    .tabtitle {
        color: $white;
        position: relative;
        
        h3 {
            width: calc( 100% - 50px );
            float: left;
            color: inherit;
            margin: 0px;
            padding: 10px 0px 10px 10px;
            line-height: 30px;
            box-sizing: border-box;
        }
        
        .toggle {
            font-size: 1.2rem;
            background-color: rgba( 255, 255, 255, 0.1 );
            text-align: center;
            right: 0px;
            
            &, .fa {
                display: block;
                line-height: 50px;
                height: 50px;
                width: 50px;
                top: 0px;
            }
        }
        
        &:after {
            content: " ";
            clear: both;
            display: block;
        }
    }
    
    .tab.active {
        .fa {
            transform: rotate(-180deg);
        }
    }
    
    // Related as buttons
    @media( max-width: ( $landscape - 1px ) ) {
        .toolbar .button {
            width: 100%;
            margin-bottom: 10px;
        }
    }
    
    @media( min-width: $landscape ){
        .toolbar {
            display: flex;
            flex-flow: row wrap;
            position: relative;
            margin: 30px -10px 0px -10px;
            
            .button { flex: 1; margin: 10px;  }
        }
    }
    
    // Float buttons next to content on tablet
    @media ( min-width: $portrait ) and ( max-width : ( $landscape - 1px ) ) {
        
        .contentHolder {
            width: calc( 100% - 200px );
            float: left;
        }
        
        .toolbar {
            width: 200px;
            float: right;
            margin-right: -20px;
        }
    }

    //
    //  Widget styles
    //
    &.air {
        .titlebar {
            padding-top: 10px;
            margin-bottom: 15px;
        }
    
        .tabs {
            padding: 0px;
            margin: 0px 15px;
            width: calc( 100% - 30px );
            
            &, a, & > span {
                border-bottom: 3px solid #e4e4e4;
            }
            
            .holder { margin-bottom: 2px; }
        }
    }
    
    
    &.cloud {
        .titlebar { margin-bottom: 10px; }
    }
}
