//
//  Alphabet
//
.alphabet {
    &, .letters {
        position: relative;
        box-sizing: border-box;
    }
    
    .letters {
        a, span, label {
        
            $size: 30px;
            
            display: inline-block;
            box-sizing: border-box;
            padding: 0px 7px;
            text-align: center;
            background-color: $f5;
            border: 1px solid $e4;
            color: $softGrey;
            transform-origin: center center;
            transition: all 0.2s linear;
            min-width: $size;
            height: $size;
            line-height: $size;
        }
    
        a, label {
            background-color: $white;
            color: $mediumGrey;
    
            &:hover {
                color: $primary;
                box-shadow: 0px 0px 3px rgbA( 0, 0, 0, 0.1 );
                transform: scale( 1.2 );
            }
        }
    }
}
