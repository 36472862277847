//
//    Basic padded & marged grid
//    The grid contains rows and in the rows are tiles based on a four column layout. Each tile has a default width of 25% of the desktop size
//    A few notes:
//    - grid element is NOT optional, it defines the flexbox
//    - Container element can be used to wrap a grid or tiles, but a grid or row can also be used to wrap a container element with tiles
//    - tiles and columns are basicly the same. Tiles are used for a single widget, but columns can be used for multiple content blocks (widgets)
//

// Define a few variables
$gridMargin: 15px; // Margin of a tile or col
$gridPadding: 0px; // Padding of a tile or col
$gridSizeOffset: $gridMargin * 2; // Calculatie wat to substract of the width of a tile

.grid {
    clear: both;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
    
    // Row FixedHeight options
    .fixedHeight & {
        align-items: stretch;
    }
    
    // Generic
    &, .tile, .col {
        position: relative;
        min-height: 50px;
    }
    
    &:after {
        clear: both;
        display: block;
        content: " ";
    }
    
    //
    //    Tile and columns
    //
    .tile, .col {
        margin: $gridMargin;
        padding: $gridPadding;
        flex: 1 0 auto;
        max-width: calc(100% - #{$gridSizeOffset});
        min-width: 0px;
    }
    
    .single, .double {
        width: calc(100% - #{$gridSizeOffset});
    }
    
    @media (min-width: 576px) {
        .single {
            flex: 0 0 auto;
            width: calc(50% - #{$gridSizeOffset})
        }
        
        .double, .triple {
            flex: 0 0 auto;
            width: calc(100% - #{$gridSizeOffset})
        }
    }
    
    @media (min-width: 992px) {
        
        .tile, .col {
            width: calc(25% - #{$gridSizeOffset});
        }
        
        .single {
            flex: 0 0 auto;
            width: calc(25% - #{$gridSizeOffset});
        }
        
        .double {
            flex: 0 0 auto;
            width: calc(50% - #{$gridSizeOffset});
        }
        
        .triple {
            flex: 0 0 auto;
            width: calc(75% - #{$gridSizeOffset});
        }
    }
    
    .tile.full, .col.full {
        width: calc(100% - #{$gridSizeOffset});
    }
}

// Counter container margin
.container .grid {
    margin-left: ($gridMargin * -1);
    margin-right: ($gridMargin * -1);
}

//
//    Nested grids
//
.grid {
    &, .grid .tile {
        padding-top: 0px;
        padding-bottom: 0px;
    }
    
    // Single
    .single, .double {
        .grid .single {
            width: 100%;
        }
    }
    
    // Double
    .double {
        @media (min-width: 992px) {
            .grid .single {
                flex: 0 0 auto;
                width: calc(50% - #{$gridSizeOffset});
            }
        }
    }
    
    // Triple
    .triple {
        @media (min-width: 576px) {
            .grid .single {
                flex: 0 0 auto;
                width: calc(33.333333% - #{$gridSizeOffset});
            }
            
            .grid .double {
                flex: 0 0 auto;
                width: calc(66.666667% - #{$gridSizeOffset});
            }
        }
        
        .grid .triple {
            flex: 0 0 auto;
            width: 100%;
        }
    }
}

// Remove padding for full tiles in noPadding rows / grids
.noPadding {
    .tile.full {
        padding-top: 0px;
        padding-bottom: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
    }
    
    .grid .grid .tile.full { margin-top: 15px; }
}

// Remove negative margin in full width container
.row > .grid {
    margin-left: 0px;
    margin-right: 0px;
}

.row.noPadding > .grid .tile.full {
    padding: 0px;
    margin: 0px;
    width: 100%;
    flex-basis: 100%;
    max-width: 100%;
}

//
//  No margin grid
//  - doesnt include the default margin
//
.grid.no-margin {
    margin-left: 0px;
    margin-right: 0px;
    
    .tile, .col {
        margin: 0px;
        max-width: 100%;
    }
    
    .single, .double { width: 100%; }
    
    @media (min-width: 576px) {
        .single { width: 50%; }
        .double, .triple { width: 100%; }
    }
    
    @media (min-width: 992px) {
        .tile, .col, .single { width: 25%; }
        .double { width: 50%; }
        .triple { width: 75%; }
    }
    
    .tile.full, .col.full { width: 100%; }
}

// Overwrite nested no-margin grid

// Single
.single, .double {
    .grid.no-margin .single { width: 100%; }
}

// Double
.double {
    @media (min-width: 992px) {
        .grid.no-margin .single { width: 50%; }
    }
}

// Triple
.triple {
    @media (min-width: 576px) {
        .grid.no-margin .single { width: 33.333333%; }
        .grid.no-margin .double { width: 66.666667%; }
    }
    .grid.no-margin .triple { width: 100%; }
}
