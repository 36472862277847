//
//    Styling forum overview
//
$forum-blue: #37afe4;
$forum-grey: #707070;

@import "generic";
@import "overview";
@import "message";
@import "place-reaction";
@import "forum-window";
@import "upload-files";

// Category specific
.column.forum {
    position: relative;

    .overview-intro {
        &, &:after { border: 0px; }
    }
    
    & > .new-message {
        position: absolute;
        right: 0px;
        top: 0px;
    }
}

// Hide related block
.column.forum ~ .sidebar .related {
    display: none;
}

// Publication specific
