//
//  Base and default elements
//

// Reset
&, & *, .employees {
    position: relative;
    box-sizing: border-box;
    margin: 0px;
}

&, .employees, .group, .block, .sub-block, .fieldgroup {
    @include clearAfter();
}

.employees { margin: 20px 0px 50px 0px; }

.group, .sub-block {
    background-color: $white;
    padding: 25px;
    margin-bottom: 25px;
}

// Base layout

@media( min-width: $portrait ) {
    .profile-card {
        width: 290px;
        float: left;
    }
    
    .profile-content {
        width: calc(100% - 290px);
        float: right;
    }
}

// FrontEnd specific nonvisual
.profile .profile-nonvisual {
    display: none;
    position: absolute;
    left: -100vw;
}

// Headers
@mixin profileHeader() {
    color: $darkGrey;
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 1rem;
}

h2, h3, h4, h5 {
    @include profileHeader();
}

.profile .hide-header {
    & > h2, & > h3, & > h4 { display: none; }
}

// Flexbox grid
.profile-tab-content .block {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-content: center;
    
    // Fix for clearAfter in flexbox
    &:after { flex: 0 0 100%; }
    
    // Full and floating blocks
    .sub-block {
        flex: 1 1 100%;
        overflow: hidden;
    }
}

@media( min-width: $portrait ) {
    .profile .block .half { flex-basis: calc(50% - 12px); }
}

.profile-tab-content {
    display: none;
    
    &.active {
        display: block;
    }
}
