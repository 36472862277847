//
//  Generic items list / overivww
//

// Generic item
.category {
    .item {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        position: relative;
        text-decoration: none;
        padding: 15px;
        margin: 0px -15px;
        transition: all 0.15s linear;

        h3 {
            margin: 0px;
            font-weight: bold;
            font-size: 1.1rem;

            @media (min-width: $portrait) {
                font-size: 1.4rem;
            }
        }

        .content, .item-content {
            flex: 1 1 auto;
        }

        .thumb, .img {
            margin-right: 30px;
        }

        .thumb {
            flex: 0 0 120px;
            height: auto;
        }

        .img {
            flex: 0 0 120px;
            height: 80px;

            @media ( min-width: $portrait ) {
                flex-basis: 220px;
                height: 120px;
            }
        }

        p, .description {
            font-size: 0.9rem;
            color: $darkGrey;
            margin: 15px 0px;

        }

        .more {
            display: block;
            text-align: right;
        }

        .edit {
            font-size: 1rem;
            font-weight: normal;
            position: absolute;
            top: 25px;
            right: 10px;
            z-index: 5;
        }
    }

    a.item {
        &:hover {
			background-color: $white;
			box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15 );
		}
    }
}

.item .img {
    .reactions, .time {
        position: absolute;
        left: 10px;
        bottom: 10px;
        font-size: 0.9rem;
        color: #FFFFFF;
        font-weight: bold;
        text-shadow: 2px 2px rgba(0, 0, 0, 0.3);
        z-index: 3;
        background: rgba(0, 0, 0, 0.3);
        padding: 0px 5px;
    }

    .time {
        right: 10px;
        left: auto;
    }
    .reactions {
        &:before {
            content: "\e61f";
            font-family: "defaultIcons";
            margin-right: 2px;
        }

        span { display: none; }
    }
}
