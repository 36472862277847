#page.expandedHeader {

    #wrapper { clear: both; }
    #header { display: block; }

    #navigation {
        top: 0px;

        .light, .light-alt { box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15); }

        .skip { top: -110px; }
    }

    #logo {
        width: 50px;
        margin-right: 0px;
    }

    #assistent {
        width: auto;
        margin: 0px;

        .quicksearch {
            min-width: 240px;
        }

        .contrast {
            display: block;
            position: relative;
            float: right;
            margin: 42px 0px 10px 10px;
            border: 1px solid #999999;
            line-height: 13px;
            background-color: $white;
            color: $darkGrey;
            border-radius: 5px;
            padding: 5px 10px;
        }
    }

    @media( min-width: $mobileLandscape ) {
        #logo { width: 40%; }
        #assistent .quicksearch { min-width: 250px; }
        #navigation.fixed { top: -60px; }
    }

    @media ( min-width: $portrait ) {
        #navigation.fixed {
            position: fixed;
            top: -60px;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
            transition: margin-top 0.5s;
            margin-top: 60px;

            .menu {
                border: 0px;
                box-shadow: none;
            }
        }

        &.fixed { padding-top: 50px; }
    }

    @media ( max-width: ($landscape - 1px ) ) {
        #logo { width: 100%; }
    }
}

@media (min-width: $portrait) {
	#page.fixed { padding-top: 50px; }
}
