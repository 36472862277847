//
//  Page head
//  - element contains main content h1 and breadcrumb
//
.page-head {
    position: relative;
    overflow: hidden;

    .zoeken & {
        display: none;
    }
}
