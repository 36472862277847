// Person today box
.today {
    .person {
        border: 1px solid $softGrey;
        text-align: center;
        padding: 20px 0px;
        background-color: $f5;
        
        & + .person { border-top-width: 0px; }
        
        .profile, .profile-info {
            float: none;
            margin: auto;
        }
    }
    
    .no-content-today {
        
        font-size: 1.2rem;
        text-align: center;
        color: $lightGrey;
        font-style: italic;
        margin: auto;
    }
}

@media ( min-width: $mobileLandscape ) {
    &:not( .single ) {
        .today .person {
            border-width: 1px;
            
            & + .person { border-left-width: 0px; }
        }
    }
}
