//
//  Overview of forum messages
//  - used for category view or widget
//
.forum-oveview {
    
    // Forum message
    .forum-message {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    
        .date, .title-holder {
            color: inherit;
            text-decoration: none;
        }
        
        abbr {
            text-decoration: none;
            font-style: normal;
        }
        
        p:last-of-type { margin: 0px; }
    }
    
    .content-holder, .title-holder {
        flex: 1 1 auto;
        margin-left: 20px;
    
        .meta {
            font-style: normal;
            font-size: 0.85rem;
        
            .reaction-user, .label {
                display: inline-block;
                padding-right: 20px;
            }
        
            .label { color: $mediumGrey; }
        }
    }
    
    .title-holder {
        &:hover {
            color: $forum-grey;
        
            .meta { opacity: 0.8; }
        }
        
        .title { font-size: 1.2rem; }
    }
    
    .comments-total {
        color: $forum-grey;
        font-size: 1.6rem;
        flex: 0 0 60px;
    
        .icon { color: $green; }
    }
    
    .react-button {
        font-size: 0.9rem;
        padding: 10px;
        margin-left: 20px;
        border-radius: 3px;
        position: relative;
        transition: all 0.2s linear;
        flex: 0 0 auto;
        
        &:before {
            content: " ";
            border-left: 2px dotted $forum-grey;
            margin: 0px 10px;
            position: absolute;
            left: -15px;
            height: 20px;
        }
        
        &:hover {
            background-color: $f5;
            color: $forum-grey;
            text-decoration: none;
        }
        
        .icon {
            padding-left: 10px;
            font-size: 1rem;
        }
    }
    
    .profile-icon {
        flex: 0 0 auto;
        align-self: flex-start;
    }
    
    // Smaller message box
    .smaller-box {
        .title-holder .title { font-size: 1rem; }
        
        .profile-icon {
            @include equalAll(30px);
        }
        
        .message-date {
            color: $forum-grey;
            margin-left: 20px;
            flex-basis: 45px;
            text-decoration: none;
        }
    
        .comments-total {
            font-size: 1rem;
            flex-basis: 45px;
            text-align: right;
            margin-right: 10px;
            
            .icon {
                float: left;
                margin-top: 3px;
            }
        }
    }
    
    .forum-year-bar {
        display: block;
        margin: 30px 0px 10px 0px;
        clear: both;
        font-size: 0.9rem;
        
        & > span, & > a {
            display: inline-block;
            padding: 2px 5px;
        }
        
        a {
            display: inline-block;
            color: $forum-blue;
            border-radius: 3px;
            
            &:hover {
                background-color: $e4;
                color: $forum-grey;
            }
        }
        
        .label { padding-left: 0px; }
    }
}
