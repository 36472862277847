.toggleblock
{
	box-sizing: border-box;
	clear: both;
	position: relative;
	margin-bottom: 10px;
	padding: 1% 2%;
	min-height: 40px;
	background: #eee;

	&.clean { padding: 0px; background: transparent; }
}

.toggleblock > ul, .toggleblock > div, .toggleblock > form { display: none; }

.toggleblock {
	.toggle-header {
		display: flex;

		& > span {
			flex: 1 1 auto;
			pointer-events: none;
		}
	}

	.toggle-button {
		min-width: 24px;
		text-align: center;
		font-size: 15pt;
		border-radius: 3px;
		border: 0px;
		background-color: transparent;
		cursor: pointer;

		&:focus-within {
			outline: 2px dotted $focus-outline-color;
			outline-offset: 2px;
		};

		.icn {
			pointer-events: none;
			transition: all 0.15s linear;
		}
	}

	&.opened, &.active {
		& > ul, & > div, & > form {
			display: block;
		}

		h2, h3 { margin-bottom: 10px; }
		.toggle-button .icn { transform: rotate(90deg); }
	}
}

/* hide some stuff: mostly forms */
.toggleblock legend { display: none; }

.toggle.pointer:after { content: "\e6d0"; }
.toggle.pointer.opened:after { content: "\e6ce"; }

/* Generic toggle button */
.toggle { cursor: pointer; }

span.toggle, a.toggle {
	position: absolute;
	right: 9px;
	top: 9px;
}

.toggle:after {
	margin-left: 5px;
	font-family: 'FontAwesome';
	font-size: 1.4rem;
	position: relative;
	line-height: 0px;
	top: 4px;
}

.toggle.menu:after, .menu .toggle:after { content: "\f0c9"; }
.toggle.menu.opened:after, .menu .toggle.opened:after { content: "\f00d"; }
.toggle.pointer:after { content: "\f054"; }
.toggle.pointer.opened:after { content: "\f078"; }

.toggle.left {
	position: relative;
	clear: both;
	left: auto;
	top: -1px;
	margin-right: 5px;

	&.opened { margin-bottom: 10px; }
}

h2.toggle:after, h3.toggle:after {
	float: right;
	font-size: 1.4rem;
	line-height: 1.6;
	top: -1px;
}
