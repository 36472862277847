//
//  Last events widget
//  - shows a large image/tile of a single event
//
.widget.lastEvent {
    
    .holder { display: block; }
    
    .textbox {
        position: absolute;
        right: 0px;
        bottom: 15px;
        background-color: rgba( 255, 255, 255, 0.8 );
        padding: 15px;
        max-width: 300px;
        width: 95%;
        
        &:before {
            content: " ";
            position: absolute;
            left: 0px;
            top: 0px;
            width: 5px;
            height: 100%;
        }
    
        .date {
            font-size: 24px;
            color: inherit;
            text-align: center;
            display: block;
            width: 50px;
            line-height: 1;
            box-sizing: border-box;
            float: left;
            margin-right: 10px;
        
            abbr {
                font-size: 0.9rem;
                display: block;
                clear: both;
                text-decoration: none;
                margin-top: 3px;
                color: $mediumGrey;
            }
        }
    
        .itemcontent {
            float: right;
            width: calc( 100% - 60px );
        
            .time {
                color: $lightGrey;
                margin-top: -2px;
                display: block;
            }
        
            .description {
                margin: 5px 0px 0px 0px;
                color: $mediumGrey;
            }
        }
    }
    
    // Style specifics
    
    &.cloud, &.rain {
        .content { padding: 0px; }
    }
    
    &.bigrain {
        .date {
            margin-top: 4px;
            border-right: 1px dotted $e4;
            width: 60px;
            margin-right: 10px;
            
            .nr {
                font-weight: bold;
                font-family: 'Oswald', sans-serif;
            }
        }
        
        .itemcontent { width: calc(100% - 70px); }
    }
}
